import React from 'react';
import { Table, Tooltip, Button } from 'antd';
import { CheckCircleTwoTone, HeartTwoTone } from '@ant-design/icons';
import moment from 'moment';

import './styles.css';

const { Column, ColumnGroup } = Table;
const PatientTable = (props) => {
  const getDay = (date) => {
    if (date !== null) {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return startDate.format('dddd').substring(0, 3);
    }
    return <text className="date-text">No Data</text>;
  };
  const getTime = (date) => {
    if (date !== null) {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return `${startDate.format('D')} ${startDate.format('MMMM')}`;
    } else return null;
  };
  return (
    <Table
      onRow={(record) => {
        return {
          onClick: () => {
            props.push(record.id);
          },
        };
      }}
      size="small"
      dataSource={props.data}
      scroll={{ y: 600 }}
      pagination={{ pageSize: 6, position: 'bottomLeft' }}>
      <Column
        title="#"
        dataIndex="image"
        key="image"
        render={(image) => (
          <>
            {
              <img
                src={
                  image != null
                    ? image
                    : 'https://www.duffyduffylaw.com/wp-content/uploads/2018/05/user-placeholder-200x250.jpg'
                }
                className="table-img"
              />
            }
          </>
        )}
      />
      <Column title="First Name" dataIndex="firstname" key="firstname" />
      <Column title="Last Name" dataIndex="lastname" key="lastname" />
      <Column
        title="Last Update"
        dataIndex="lastLoginDatetime"
        key="lastLoginDatetime"
        render={(lastLoginDatetime) => (
          <>
            {getDay(lastLoginDatetime)}
            <br />
            {getTime(lastLoginDatetime)}
          </>
        )}
      />
      <ColumnGroup title="Overview">
        <Column
          title="Stress Av"
          dataIndex="chart"
          key="chart"
          render={(chart) => (
            <>
              {
                <>
                  <text className="table-text">{chart.stress}</text>
                </>
              }
            </>
          )}
        />
        <Column
          title="Panics"
          dataIndex="chart"
          key="chart"
          render={(chart) => (
            <>
              {
                <>
                  <text className="table-text">{chart.totalPanics}</text>
                </>
              }
            </>
          )}
        />
        <Column
          title="QA"
          dataIndex="chart"
          key="chart"
          render={(chart) => (
            <>
              {
                <>
                  <text className="table-text">{chart.totalQA}</text>
                </>
              }
            </>
          )}
        />
        <Column
          title="Panic Mode"
          dataIndex="panic"
          key="panic"
          render={(panic) => (
            <>
              {
                <>
                  {panic === true ? (
                    <Tooltip title="Panic Mode Is On">
                      <HeartTwoTone
                        className="table-icon"
                        twoToneColor="#fe8781"
                      />
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        height: '2px',
                        width: '8px',
                        backgroundColor: '#404040',
                      }}></div>
                  )}
                </>
              }
            </>
          )}
        />
        <Column
          title="Parents Connected"
          dataIndex="parentId"
          key="parentId"
          render={(parentId) => (
            <>
              {
                <>
                  {parentId !== null ? (
                    <Tooltip title="Parents Connected">
                      <CheckCircleTwoTone
                        twoToneColor="#52c41a"
                        className="table-icon"
                      />
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        height: '2px',
                        width: '8px',
                        backgroundColor: '#404040',
                      }}></div>
                  )}
                </>
              }
            </>
          )}
        />
        <Column
          title="New Message"
          dataIndex="id"
          key="id"
          render={(id) => (
            <>
              {
                <>
                  {props.badges.find((a) => a.userId === id).badgeNumber ===
                  0 ? (
                    <div
                      style={{
                        height: '2px',
                        width: '8px',
                        backgroundColor: '#404040',
                      }}></div>
                  ) : (
                    <text className="table-badge">
                      {props.badges.find((a) => a.userId === id).badgeNumber}
                    </text>
                  )}
                </>
              }
            </>
          )}
        />
      </ColumnGroup>
      <Column
        title="Action"
        dataIndex="id"
        key="id"
        fixed="right"
        render={(id) => (
          <>
            {
              <Button
                className="btn-typo-2 border-typo-1"
                onClick={() => {
                  props.push(id);
                }}>
                More ...
              </Button>
            }
          </>
        )}
      />
    </Table>
  );
};

export default PatientTable;
