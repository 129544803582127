import React from 'react';
import { Row, Col, Tooltip } from 'antd';

const Privacy = ({ history }) => {
  return (
    <>
      <Row justify="center" align="middle" className="main-row">
        <Col>
          <Tooltip placement="bottom" title="Breeze">
            <img
              src="./../../img/Doctors.png"
              alt="Smile"
              className="logo-img"
              onClick={() => history.push('/login')}
            />
          </Tooltip>
        </Col>
      </Row>
      <section>
        <article>
          <p style={{ marginTop: '30px' }}>
            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING Breeze MOBILE
            APPLICATION
          </p>
          <p style={{ textDecoration: 'underline' }}>
            <strong>1.&nbsp; Terms of Use</strong>
          </p>
          <p>
            By using this application (the &ldquo;Application&rdquo;), you are
            agreeing to these terms of use. If you (the &ldquo;user&rdquo;) do
            not agree to all of these terms of use, do not use this application.
            Breeze may revise and update these terms without notice at any time.
            Your continued use of this Application means that you accept those
            changes. You are not permitted to change these terms of use.
          </p>
          <p>
            Breeze is located in Toronto, Ontario, Canada. The laws of the
            province of Ontario shall govern this agreement.
          </p>
          <p style={{ textDecoration: 'underline' }}>
            <strong>2.&nbsp; No Medical Advice</strong>
          </p>
          <p>
            This Application is provided for informational purposes only. This
            Application is not a substitute for professional medical advice,
            diagnosis or treatment. If you rely on any information appearing on
            or provided in relation to this application, you do so solely at
            your own risk.
          </p>
          <p>
            This Application is designed to help the user build and access their
            suicide safety plan, and provide them with suicide related
            resources. However, Breeze has no control over, and makes no
            representations or warranties, expressed or implied, regarding the
            use of this Application or the use or interpretation of any
            information stored on this Application. You are solely responsible
            for communicating any health concerns and questions to your health
            care provider(s). This Application does not provide Breeze or any
            health care providers with access to any information that you enter
            into the Application.
          </p>
          <p>
            IF YOU REQUIRE EMERGENCY HELP, CONTACT YOUR LOCAL EMERGENCY SERVICES
            OR CLICK ON THE &lsquo;GET HELP NOW&rsquo; OR &lsquo;CALL 911&rsquo;
            BUTTON.&nbsp; IN ONTARIO YOU CAN CALL 911 OR GO TO YOUR LOCAL
            HOSPITAL EMERGENCY ROOM OR USING HOTLINE OR CHATLINE OF YOUR
            SELECTED ZONE.&nbsp; NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR
            DELAY SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ OR LEARNED
            THROUGH THE USE OF THIS APPLICATION.
          </p>
          <p style={{ textDecoration: 'underline' }}>
            <strong>3. Privacy and Confidentiality</strong>
          </p>
          <p>
            The Hope by Breeze app is meant for your personal use to help you
            create a Safety Plan and support you when experiencing thoughts of
            suicide. It has been designed to respect your privacy &ndash; you
            control what information you put in the app and who you share that
            information with.
          </p>
          <p>
            The app is stored on your device. This means Breeze will not collect
            any information you put in the app and the app will not track your
            location. If you choose to share your safety plan, such as with
            someone in your family or with your care provider, the app will use
            your email and your contacts on your device to email your safety
            plan to whoever you choose. Because the app is on your device, the
            security of those emails depends on your device and your email
            service provider. If you want to add a photo to your Safety Plan,
            the app will access the photos on your device so you can include a
            picture. Accessing information on your device is your choice and the
            information stays on your device. If you share your device with
            someone else please remember the app is not password protected so
            your information in the app, such as My Journal, may be accessible
            to those people. You are responsible for ensuring the security of
            your device.
          </p>
          <p>
            Before downloading Stay Alive we advise users to set up basic
            password management on their devices. Examples of basic password
            management are: a screen lock or passcode.
          </p>
          <p>
            If you access one of the links in the app, such as a crisis line or
            resources on the Breeze website, you have left the app and the terms
            of those websites apply. If you access one of the Wellness videos,
            then you will be brought to the YouTube website. If you are logged
            in to your YouTube account, then the videos you access may be
            tracked by YouTube. If you access one of the crisis numbers through
            the app, then those calls or text messages are between you and the
            crisis centre or 911, and Breeze will not know who you contact.
          </p>
          <p>
            The information you store on the app on your device will remain
            there until you choose to delete the app or you reset your device.
            This information is not automatically backed up unless your device
            is set up that way or you choose to back it up. You can stop using
            the app and delete your information at any time.
          </p>
          <p>
            If you need technical support for using the app, you can reach out
            to Support@breezeapp.ca&nbsp;The Breeze IT support team will treat
            your email and communications with the same consideration we would
            our patient information and other information that is personal or
            sensitive.
          </p>
          <p style={{ textDecoration: 'underline' }}>
            <strong>Analytics</strong>
          </p>
          <p>
            The App Store (Apple App Store/ Google Play Store) that you accessed
            to download this Application collects certain anonymized information
            that is shared with Breeze. The data is collected for the purposes
            of understanding how the Application is used and is always aggregate
            anonymized data with no specific user information. The type of
            information collected relates to the number of times the Application
            was viewed (in the App Store), installed and/or uninstalled by
            users, as well as any ratings from users provided through the App
            Stores.&nbsp; &nbsp;
          </p>
          <p style={{ textDecoration: 'underline' }}>
            <strong>4.&nbsp;&nbsp; Disclaimers</strong>
          </p>
          <p>
            Breeze makes no representations as to any matter whatsoever,
            including accuracy, fitness for a particular purpose or
            non-infringement.
          </p>
          <p>
            Any websites linked from this Application are created by other
            organizations. Those organizations are responsible for the
            information contained within their sites. Even when this Application
            refers or links to another website, Breeze does not endorse the
            content on any other organization&rsquo;s website. Breeze does not
            control and is not responsible for the content of those websites.
            Your use of those other websites is at your own risk and subject to
            the terms of use for those sites.
          </p>
          <p>
            Although this Application does not provide any third parties with
            access to any information that the User enters into the Application,
            if you enter any personal information, including personal health
            information, with third parties you do so solely at your own risk.
          </p>
          <p style={{ textDecoration: 'underline' }}>
            <strong>5.&nbsp;&nbsp; Limitation of Liability</strong>
          </p>
          <p>
            This Application and the content provided are on an "as is" basis.
            Breeze assumes no liability for or relating to the delay, failure,
            interruption or corruption of any information in or stored on this
            Application.
          </p>
          <p>
            Breeze has no obligation to furnish any maintenance and support
            services with respect to this Application.
          </p>
          <p>
            Breeze is not liable to you or anyone else for any decision made or
            action taken based on reliance upon the information contained in or
            provided through this Application. The use of the Application is at
            your own risk. The sole and exclusive remedy for dissatisfaction
            with this Application is to stop using this Application.
          </p>
          <p>
            The Application is provided "as is" with no representations or
            warranties of any kind, express, statutory or implied, as to the
            operation of the Application, or the information, content,
            materials, or products included on the Application. To the fullest
            extent permissible by applicable law, Breeze and its officers,
            directors, employees, physicians and contractors disclaim all
            warranties, express, statutory, or implied, including, but not
            limited to, implied warranties of merchantability, fitness for a
            particular purpose or non-infringement.
          </p>
        </article>
      </section>
    </>
  );
};

export default Privacy;
