import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Notification } from 'utils/notification';

import Header from './components/Header';
import CreateArticle from './components/Create';
import UpdateArticle from './components/Update';
import articlesAPI from 'apis/articles';
import './styles.css';

const { Meta } = Card;
const ArticlesList = () => {
  const [articles, setArticles] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [tempArticles, setTempArticles] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [mood, setMood] = useState(0);

  useEffect(() => {
    loadArticles();
  }, []);
  const loadArticles = async () => {
    const res = await articlesAPI.getArticles();
    setArticles(res);
    setTempArticles(res);
  };
  const createToggle = () => {
    setMood(0);
    loadArticles();
  };
  const updateToggle = () => {
    setMood(0);
    loadArticles();
  };

  const editArticle = (item) => {
    setSelectedArticle(item);
    setMood(2);
  };
  return (
    <Col md={24} className="col-1">
      <Header
        temp={tempArticles}
        mood={mood}
        setMood={setMood}
        setArticles={setArticles}
      />
      <Row>
        {mood === 1 ? (
          <CreateArticle toggle={() => createToggle()} />
        ) : mood === 2 ? (
          <UpdateArticle
            toggle={() => updateToggle()}
            selectedArticle={selectedArticle}
          />
        ) : (
          <Row className="row-3">
            <Col className="row-3-col-1" md={2} lg={2} xs={2}>
              {articles.length > 3 && (
                <Tooltip title="Left">
                  <i
                    onClick={() => {
                      if (activeIndex !== 0) {
                        const lastItem = articles[articles.length - 1];
                        let temp = articles;
                        temp.unshift(lastItem);
                        setArticles([...temp.slice(0, temp.length - 1)]);
                      }
                    }}
                    className="fa fa-arrow-left article-icon"
                    aria-hidden="true"
                  />
                </Tooltip>
              )}
            </Col>
            <Col className="row-3-col-2" md={20} lg={20} xs={20}>
              <Row>
                {articles.length !== 0 &&
                  articles.map((item, i) => {
                    if (i < 3) {
                      return (
                        <Col
                          className={activeIndex === i ? 'active-col' : null}
                          md={6}
                          lg={6}
                          xs={6}>
                          <Card
                            className={
                              activeIndex === i
                                ? 'active-article border-typo-1'
                                : 'deactive-article border-typo-1'
                            }
                            onClick={() => setActiveIndex(i)}
                            cover={
                              activeIndex === i ? (
                                <img
                                  src={
                                    item.image === null
                                      ? require('assets/313.png')
                                      : item.image
                                  }
                                  height="313px"
                                  width="300px"
                                />
                              ) : (
                                <img
                                  src={
                                    item.image === null
                                      ? require('assets/download.png')
                                      : item.image
                                  }
                                  className="card-img"
                                />
                              )
                            }
                            actions={
                              activeIndex === i
                                ? [
                                    <Tooltip title="Delete">
                                      <DeleteOutlined
                                        onClick={() => {
                                          articlesAPI
                                            .deleteArticle(item.id)
                                            .then((res) => {
                                              Notification(
                                                'success',
                                                'article deleted successfully',
                                              );
                                              articlesAPI
                                                .getArticles()
                                                .then((response) => {
                                                  setArticles(response);
                                                  setTempArticles(response);
                                                });
                                            });
                                        }}
                                        className="delete-icon"
                                        key="delete"
                                      />
                                    </Tooltip>,
                                    <Tooltip title="Edit">
                                      <EditOutlined
                                        onClick={() => {
                                          editArticle(item);
                                        }}
                                        className="edit-icon"
                                        key="edit"
                                      />{' '}
                                    </Tooltip>,
                                  ]
                                : null
                            }>
                            <Meta
                              avatar={
                                <Tooltip
                                  title={
                                    'Writen by : ' +
                                    item.author.firstname +
                                    (item.author.lastname !== null
                                      ? item.author.lastname
                                      : '')
                                  }>
                                  <img
                                    src="https://react.semantic-ui.com/images/avatar/large/molly.png"
                                    className="card-avatar"
                                  />
                                </Tooltip>
                              }
                              title={item.title}
                              description={
                                activeIndex === i
                                  ? `${item.text
                                      .substr(0, 50)
                                      .replace('<p>', '')
                                      .replace('<br>', '')
                                      .replace('<h1>', '')
                                      .replace('</p>', '')
                                      .replace('<h1/>', '')} ...`
                                  : null
                              }
                            />
                          </Card>
                        </Col>
                      );
                    }
                  })}
                {articles.length === 0 && (
                  <text className="article-alert">No Article Available</text>
                )}
              </Row>
            </Col>
            <Col className="row-3-col-1" md={2} lg={2} xs={2}>
              {articles.length > 3 && (
                <Tooltip title="Right">
                  <i
                    onClick={() => {
                      if (activeIndex !== articles.length - 1) {
                        const firstItem = articles[0];
                        let temp = articles;
                        temp.shift();
                        setArticles([...temp, firstItem]);
                      }
                    }}
                    className="fa fa-arrow-right article-icon"
                    aria-hidden="true"
                  />
                </Tooltip>
              )}
            </Col>
          </Row>
        )}
      </Row>
    </Col>
  );
};

export default ArticlesList;
