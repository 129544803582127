import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';
import { Notification } from 'utils/notification';

export default {
  singIn: (password, email) => {
    const data = new FormData();
    data.append('grant_type', 'password');
    data.append('role', 'clinic');
    data.append('password', password);
    data.append('email', email);
    const res = axiosInstance({
      method: HTTP_METHODS.POST,
      url: '/Auth/Token',
      data,
    });
    if (res.roleId === 1 || res.roleId === 2) {
      Notification('error', '401 Unauthorized');
      return null;
    }
    return res;
  },
  upload: (file) => {
    const data = new FormData();
    data.append('files', file);
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: '/Auth/Upload',
      data,
    });
  },
  signOut: () => {
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: '/Auth/SignOut',
      headers: ApiHeader,
    });
  },
  resetPassRequest: (email) => {
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: '/Auth/ForgetPassword?email=' + email,
    });
  },
  resetPass: (username, password) => {
    const data = {
      username,
      password,
    };
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: '/Auth/ResetPassword',
      data,
    });
  },
};
