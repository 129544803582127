import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';

export default {
  deleteTask: (taskId) => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.DELETE,
      url: Role + '/DeleteTask?taskId=' + taskId,
      headers: ApiHeader,
    });
  },
  addTask: (data) => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: Role + '/Task',
      data,
      headers: ApiHeader,
    });
  },
  getBothTasks: (id) => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/BothTasks?id=' + id,
      headers: ApiHeader,
    });
  },
};
