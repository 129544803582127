export const month = [
  {
    key: 0,
    name: 'Jan',
    qa: 20,
    sui: 80,
  },
  {
    key: 1,
    name: 'Feb',
    qa: 40,
    sui: 80,
  },
  {
    key: 2,
    name: 'Mar',
    qa: 80,
    sui: 10,
  },
  {
    key: 3,
    name: 'Apr',
    qa: 20,
    sui: 80,
  },
  {
    key: 4,
    name: 'May',
    qa: 20,
    sui: 80,
  },
  {
    key: 5,
    name: 'Jun',
    qa: 30,
    sui: 80,
  },
  {
    key: 6,
    name: 'Jul',
    qa: 50,
    sui: 80,
  },
  {
    key: 7,
    name: 'Aug',
    qa: 70,
    sui: 80,
  },
  {
    key: 8,
    name: 'Sep',
    qa: 10,
    sui: 80,
  },
  {
    key: 9,
    name: 'Oct',
    qa: 35,
    sui: 80,
  },
  {
    key: 10,
    name: 'Nov',
    qa: 15,
    sui: 80,
  },
  {
    key: 11,
    name: 'Dec',
    qa: 10,
    sui: 80,
  },
];
export const panes = [
  {
    key: 0,
    name: 'Information',
  },
  {
    key: 1,
    name: 'TodoList',
  },
  {
    key: 2,
    name: 'Q&A',
  },
  {
    key: 3,
    name: 'Settings',
  },
];
export const authors = [
  {
    id: 1,
    name: 'Mark',
    isTyping: false,
    bgImageUrl: undefined,
  },
  {
    id: 2,
    name: 'Peter',
    isTyping: false,
    bgImageUrl: undefined,
  },
];
export const groupAuthors = [
  {
    id: 1,
    name: 'Mark',
    isTyping: true,
    lastSeenMessageId: 1,
  },
  {
    id: 2,
    name: 'Peter',
    isTyping: false,
    lastSeenMessageId: 2,
  },
];
