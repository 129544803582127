export const BASE_URL = 'https://brze.info:9006/api/v1/';
export const VERSION = '0.0.1';
export const HTTP_METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};
export const ROLES = {
  Doctor: 'Doctor',
  Clinic: 'Clinic',
  Admin: 'Admin',
};
