import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';

export default {
  getGroups: () => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/Groups',
      headers: ApiHeader,
    });
  },
  getGroupsDetail: (id) => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/GroupDetail?groupId=' + id,
      headers: ApiHeader,
    });
  },
};
