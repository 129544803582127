import React, { Fragment } from 'react';
import { Row, Col, Popover } from 'antd';
import moment from 'moment';

const Forth = ({ data }) => {
  const getDate = (date) => {
    if (date !== 'no data') {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return startDate.format('dddd').substring(0, 3);
    }
    return <text className="no-data-text">No Data</text>;
  };
  const getTime = (date) => {
    if (date !== 'no data') {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return `${startDate.format('D')} ${startDate.format('MMMM')}`;
    }
    return <text className="no-data-right">No Data</text>;
  };
  return (
    <Popover
      content={
        <Fragment>
          <Row className="tip-width">
            <Col span={12}>
              <text className="tip-left-text">{getDate(data.datetime)}</text>
            </Col>
            <Col span={12}>
              <text className="tip-right-text">{getTime(data.datetime)}</text>
            </Col>
          </Row>
          <Row className="tip-width">
            <Col span={16}>
              <text className="tip-data-left">Panic</text>
            </Col>
            <Col span={8}>
              <text className="tip-data-right">{data.totalPanics}</text>
            </Col>
          </Row>
          <Row className="tip-width">
            <Col span={16}>
              <text className="tip-data-left">QA</text>
            </Col>
            <Col span={8}>
              <text className="tip-data-right">{data.totalQA}</text>
            </Col>
          </Row>
          <Row className="tip-width">
            <Col span={16}>
              <text className="tip-data-left">Stress Av</text>
            </Col>
            <Col span={18}>
              <text className="tip-data-right">{data.stress}</text>
            </Col>
          </Row>
        </Fragment>
      }
      background="white"
      placement="bottom"
      radius="10"
      border="#404040"
      fontSize="10px">
      <Col span={4} className="right-side-tip">
        <Row
          style={{
            borderLeft: ' 6px solid #404040',
            height: 50 - (parseInt(data.stress) * 5) / 10,
            borderRadius: '3px',
            margin: '0px',
          }}
        />
        <Row
          style={{
            borderLeft: ' 6px solid #FE8781',
            height: (parseInt(data.stress) * 5) / 10,
            borderRadius: '3px',
            margin: '0px',
          }}
        />
      </Col>
    </Popover>
  );
};

export default Forth;
