import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { getUser } from 'utils/auth';
import ForgotPass from 'screens/ForgotPass';
import ArticlesList from 'screens/Article';
import PatientProfiles from 'screens/Profiles';
import SinglePatient from 'screens/Patient';
import Region from 'screens/Regions';
import Staff from 'screens/Staff';
import Header from 'screens/Header';
import Main from 'screens/Main';
import ClinicProfile from 'screens/Clinics';
import PublicMessenger from 'screens/Messanger';
import NotFoundPage from 'screens/NotFoundPage';
import Auth from 'screens/Auth';
import ForgotPassRequest from 'screens/ForgotPassRequest';
import Privacy from 'screens/Privacy';

const App = (props) => {
  const path = props.history.location.pathname.replace('/', '');
  const { roleId, token } = getUser();

  document.title = path || 'Dashboard';
  const showHeader = () => {
    if (
      path !== 'login' &&
      path !== '404' &&
      !path.includes('forgot_pass') &&
      !path.includes('privacy-policy')
    )
      return <Header />;
  };
  const PrivateRoute = ({ adminOnly, ...props }) => {
    if (!token) return <Redirect to="/login" />;
    if (!adminOnly || roleId === 4) return <Route {...props} />;
    return <Route path="/404" component={NotFoundPage} />;
  };
  return (
    <div className="main-div-style">
      {showHeader()}
      <Switch>
        <Route path="/login" component={Auth} />
        <Route path="/forgot_pass/:mail" component={ForgotPass} />
        <Route path="/forgot_pass_req" component={ForgotPassRequest} />
        <Route path="/privacy-policy" component={Privacy} />
        <PrivateRoute path="/home" exact component={Main} />
        <PrivateRoute path="/" exact component={Main} />
        <PrivateRoute adminOnly path="/clinics" component={ClinicProfile} />
        <PrivateRoute path="/Messenger" component={PublicMessenger} />
        <PrivateRoute path="/region" component={Region} />
        <PrivateRoute path="/articles" component={ArticlesList} />
        <PrivateRoute path="/profiles" component={PatientProfiles} />
        <PrivateRoute path="/info" component={SinglePatient} />
        <PrivateRoute path="/staff" component={Staff} />
        <Route path="/404" component={NotFoundPage} />
        <Redirect from="*" to="/404" />
      </Switch>
    </div>
  );
};
export default withRouter(App);
