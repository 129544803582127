import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Button } from 'antd';

import { Notification } from 'utils/notification';
import Left from 'screens/Patient/components/Left';
import Right from 'screens/Patient/components/Right';
import First from 'screens/Patient/components/First';
import Second from 'screens/Patient/components/Second';
import Third from 'screens/Patient/components/Third';
import Fourth from 'screens/Patient/components/Fourth';
import Fifth from 'screens/Patient/components/Fifth';
import Sixth from 'screens/Patient/components/Sixth';
import clinic from 'apis/clinic';
import './styles.css';

const { Option } = Select;
const defaultImg =
  'https://www.duffyduffylaw.com/wp-content/uploads/2018/05/user-placeholder-200x250.jpg';
const Info = ({ info, ...props }) => {
  const [normalChart, setNormalChart] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [tempDocs, setTempDocs] = useState([]);
  const user = {
    sex: info.gender === 0 ? 'Male' : info.gender === 1 ? 'Female' : 'Others',
    Status: info.online ? 'Online' : 'Offline',
    level: info.lastStressLevel,
  };

  useEffect(() => {
    normlize();
    clinic.getDoctors().then((response) => {
      let doctorList = [];
      response.forEach((item) => {
        doctorList.push(
          <Option value={item.id}>
            {item.firstname} {item.lastname}
          </Option>,
        );
      });
      setTempDocs(response);
      setDoctors(doctorList);
    });
  }, []);

  const normlize = () => {
    if (info.charts !== undefined) {
      if (info.charts !== null) {
        if (info.charts.length !== 0) {
          if (info.charts === 18) {
            setNormalChart(info.charts);
            props.load();
          } else {
            const mockData = {
              datetime: 'no data',
              totalPanics: 0,
              totalQA: 0,
              stress: '0',
            };
            let minus = 18 - info.charts.length;

            let additionalData = [];
            for (let i = 1; i <= minus; i++) {
              additionalData.push(mockData);
            }
            let FinalData = [...info.charts, ...additionalData];
            setNormalChart(FinalData);
            props.load();
          }
        } else {
          const mockData = {
            datetime: 'no data',
            totalPanics: 0,
            totalQA: 0,
            stress: '0',
          };
          let minus = 18 - info.charts.length;

          let additionalData = [];
          for (let i = 1; i <= minus; i++) {
            additionalData.push(mockData);
          }
          let FinalData = [...info.charts, ...additionalData];
          setNormalChart(FinalData);
          props.load();
        }
      } else {
        const mockData = {
          datetime: 'no data',
          totalPanics: 0,
          totalQA: 0,
          stress: '0',
        };
        let minus = 18;

        let additionalData = [];
        for (let i = 1; i <= minus; i++) {
          additionalData.push(mockData);
        }
        let FinalData = [...additionalData];
        setNormalChart(FinalData);
        props.load();
      }
    }
  };
  const returnFirstName = () => {
    try {
      return tempDocs.length !== 0 && info.doctorId !== null
        ? tempDocs.find((item) => item.id === info.doctorId).firstname +
            ' ' +
            tempDocs.find((item) => item.id === info.doctorId).lastname
        : 'Set Clinician';
    } catch (e) {
      return 'Set Clinician';
    }
  };
  return (
    <Row justify="center" className="first-row-padding">
      <Col span={4}>
        <img
          src={info.image === null ? defaultImg : info.image}
          alt="Card image"
          className="info-img border-typo-2"
        />
      </Col>
      <Col span={14}>
        <Row>
          <Col span={8}>
            <Left data={normalChart.slice(0, 6)} sex={user.sex} />
          </Col>
          <Col span={8}>
            <Row justify="center">
              {normalChart.length > 0 ? (
                <>
                  <First data={normalChart[7]} />
                  <Second data={normalChart[8]} />
                  <Third data={normalChart[9]} />
                  <Fourth data={normalChart[10]} />
                  <Fifth data={normalChart[11]} />
                  <Sixth data={normalChart[12]} />
                </>
              ) : null}
            </Row>
            <Row className="info-last-row">
              <div className="last-row-div">
                <Row className="last-row-center">
                  <text
                    style={{
                      fontWeight: 'bold',
                      fontSize: '55px',
                      color: 'black',
                      paddingBottom: '5px',
                      marginTop: '-10px',
                    }}>
                    {user.level !== null ? user.level : 0}
                  </text>
                </Row>
                <Row className="last-row-center">
                  <text className="stress-text">Stress</text>
                </Row>
              </div>
            </Row>
          </Col>
          <Col span={8}>
            <Right data={normalChart.slice(12, 18)} Status={user.Status} />
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <>
          <Row>
            <Col span={4}>
              {info.lastMood === null ? (
                <img src={require('assets/poker.png')} />
              ) : info.lastMood === 'sad' ? (
                <img src={require('assets/sad.png')} />
              ) : info.lastMood === 'happy' ? (
                <img src={require('assets/smile.png')} />
              ) : (
                <img src={require('assets/poker.png')} />
              )}
            </Col>
            <Col span={16}>
              <text className="selection-text">Parent Selection</text>
            </Col>
            <Col span={4} />
          </Row>
          <Button
            size="large"
            onClick={() => {
              clinic.sendNotification(info.id).then(() => {
                Notification(
                  'success',
                  'Notification Has Been Sent Successfully',
                );
              });
            }}
            className="notif-btn border-typo-2">
            Send Notification
          </Button>
          {JSON.parse(localStorage.getItem('user')).roleId === 3 &&
          tempDocs.length !== 0 ? (
            <Select
              onChange={(e) => {
                clinic.setDoctor(e, info.id).then(() => {
                  Notification(
                    'success',
                    'Clinician Has Been Set Successfully',
                  );
                });
              }}
              defaultValue={returnFirstName()}
              className="select-2"
              placeholder="Set Clinician">
              {doctors}
            </Select>
          ) : null}

          <Select
            onChange={(e) => {
              clinic.setPatientInterval(e, info.id).then(() => {
                Notification('success', 'Interval Has Been Set Successfully');
              });
            }}
            className="select-2"
            placeholder="Strees Selection Interval">
            <Option value="1">One Time Per Day</Option>
            <Option value="2">Two Times Per Day</Option>
            <Option value="3">Three Times Per Day</Option>
            <Option value="4">Four Times Per Day</Option>
          </Select>
        </>
      </Col>
    </Row>
  );
};
export default Info;
