import React, { useState, useEffect } from 'react';
import ReactExport from 'react-export-excel';
import moment from 'moment';

import clinic from 'apis/clinic';
import patient from 'apis/patient';
import './styles.css';

const Pdf = ({ id, patientInfo }) => {
  const [dataSet1, setDataSet1] = useState([]);
  const [dataSet2, setDataSet2] = useState([]);
  const [dataSet3, setDataSet3] = useState([]);
  const [dataSet4, setDataSet4] = useState([]);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  useEffect(() => {
    (async () => {
      const response = await patient.getPatientDetail(id);
      loadReport(response);
      clinic.getLastRecord(id).then((res) => {
        let data = res.stressLevels;
        let data1 = res.suicides;
        let data2 = res.selfharms;
        let last = [];
        let suicides = [];
        let selfharms = [];
        if (data !== null && data.length > 0) {
          data.map((i) => {
            last.push({
              date: i.datetime,
              stress: i.stressLevel,
            });
            setDataSet2(last);
          });
        } else {
          last.push({
            datetime: null,
            stress: null,
          });
          setDataSet2(last);
        }
        if (data1 !== null && data1.length > 0) {
          data1.map((i) => {
            suicides.push({
              date: i.datetime,
              first: i.firstAns,
              second: i.secondAns,
              third: i.thirdAns,
              forth: i.forthAns,
            });
            setDataSet4(suicides);
          });
        } else {
          suicides.push({
            datetime: null,
            first: null,
            second: null,
            third: null,
            forth: null,
          });
          setDataSet4(suicides);
        }
        if (data2 !== null && data2.length > 0) {
          data2.map((i) => {
            selfharms.push({
              date: i.datetime,
              first: i.firstAns,
              second: i.secondAns,
              third: i.thirdAns,
              forth: i.forthAns,
            });
            setDataSet3(selfharms);
          });
        } else {
          selfharms.push({
            datetime: null,
            first: null,
            second: null,
            third: null,
            forth: null,
          });
          setDataSet3(selfharms);
        }
      });
    })();
  }, []);
  const getDate = (date) => {
    if (date !== 'no data') {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return startDate.format('dddd').substring(0, 3);
    } else return <text className="no-data-text">No Data</text>;
  };
  const getTime = (date) => {
    if (date !== 'no data') {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return `${startDate.format('D')} ${startDate.format('MMMM')}`;
    } else return <text className="no-data-right">No Data</text>;
  };
  const loadReport = (response) => {
    const info = response;
    let data = [];
    data.push({
      name: info.firstname,
      age: info.age,
      gender: info.gender,
      official: info.official,
      doctorName: info.doctorName,
      lastStressLevel: info.lastStressLevel,
      phn: info.phn,
      lastMood: info.lastMood,
      email: info.email,
    });
    setDataSet1(data);
  };
  return (
    <ExcelFile
      filename={patientInfo.firstname}
      element={
        <i aria-hidden="true" className="fa fa-sticky-note-o pdf-icon"></i>
      }>
      <ExcelSheet data={dataSet1} name="Patient History">
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Age" value="age" />
        <ExcelColumn
          label="Gender"
          value={(col) =>
            col.gender === 1 ? 'Male' : col.gender === 2 ? 'Female' : 'Other'
          }
        />
        <ExcelColumn
          label="Clinician"
          value={(col) => (col.doctorName !== null ? col.doctorName : '---')}
        />
        <ExcelColumn
          label="Last Stress Level"
          value={(col) =>
            col.lastStressLevel !== null ? col.lastStressLevel : '---'
          }
        />
        <ExcelColumn
          label="Phn"
          value={(col) => (col.phn !== null ? col.phn : '---')}
        />
        <ExcelColumn
          label="Mode"
          value={(col) => (col.lastMood !== null ? col.lastMood : '---')}
        />
        <ExcelColumn
          label="official"
          value={(col) => (col.official ? 'Yes' : 'No')}
        />
      </ExcelSheet>
      <ExcelSheet data={dataSet2} name="Stress Levels">
        <ExcelColumn
          label="Date"
          value={(col) =>
            col.date !== null
              ? getDate(col.date) + '  ' + getTime(col.date)
              : '---'
          }
        />
        <ExcelColumn label="Stress" value="stress" />
      </ExcelSheet>
      <ExcelSheet data={dataSet4} name="Suicides">
        <ExcelColumn
          label="Date"
          value={(col) =>
            col.date !== null
              ? getDate(col.date) + '  ' + getTime(col.date)
              : '---'
          }
        />
        <ExcelColumn
          label="Select The Suicidal Thoughts Level"
          value={(col) => (col.first !== null ? col.first : '---')}
        />
        <ExcelColumn
          label="Any plan to act suicide ?"
          value={(col) => (col.second !== null ? col.second : '---')}
        />
        <ExcelColumn
          label="Do you feel safe now ?"
          value={(col) => (col.third !== null ? col.third : '---')}
        />
        <ExcelColumn
          label="Compared to last time we checked, You feel"
          value={(col) => (col.forth !== null ? col.forth : '---')}
        />
      </ExcelSheet>
      <ExcelSheet data={dataSet3} name="Self Harms">
        <ExcelColumn
          label="Date"
          value={(col) =>
            col.date !== null
              ? getDate(col.date) + '  ' + getTime(col.date)
              : '---'
          }
        />
        <ExcelColumn
          label="Select The Self Harm Thoughts Level"
          value={(col) => (col.first !== null ? col.first : '---')}
        />
        <ExcelColumn
          label="Any self harming thoughts ?"
          value={(col) => (col.second !== null ? col.second : '---')}
        />
        <ExcelColumn
          label="Any self harm since the last time we checked ?"
          value={(col) => (col.third !== null ? col.third : '---')}
        />
        <ExcelColumn
          label="Compared to last time we checked, You feel"
          value={(col) => (col.forth !== null ? col.forth : '---')}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};
export default Pdf;
