import React, { useState } from 'react';
import { Row, Col } from 'antd';
import SettingCarousel from 'components/SettingCarousel';
import './styles.css';

const SymptomsBar = ({ symps, setSymptonsIds }) => {
  const [stressors, setStressors] = useState(false);
  const [early, setEarly] = useState(false);
  const [feelBetter, setFeelBetter] = useState(false);

  const setStressorTrue = () => {
    setStressors(true);
    setFeelBetter(false);
    setEarly(false);
  };
  const setFeelBetterTrue = () => {
    setStressors(false);
    setFeelBetter(true);
    setEarly(false);
  };
  const setEarlyTrue = () => {
    setStressors(false);
    setFeelBetter(false);
    setEarly(true);
  };
  return (
    <>
      <Row className="setting-row-padding">
        <Col span={20}>
          <text
            className={
              stressors ? 'setting-active-text' : 'setting-deactive-text'
            }>
            Stressors
          </text>
        </Col>
        <Col span={4}>
          <i
            onClick={
              stressors ? () => setStressors(false) : () => setStressorTrue()
            }
            aria-hidden="true"
            className={`fa fa-arrow-${stressors ? 'up' : 'down'} setting-${
              stressors ? 'default' : 'active'
            }-icon`}></i>
        </Col>
      </Row>

      <Row className={stressors ? 'setting-block' : 'setting-none'}>
        <SettingCarousel
          setItems={(array) => {
            setSymptonsIds(array);
          }}
          editable={true}
          data={symps.filter((a) => a.typeId === 2)}
        />
      </Row>

      <Row className="early-row">
        <Col span={20}>
          <text
            className={early ? 'setting-active-text' : 'setting-deactive-text'}>
            Early Warning Signs
          </text>
        </Col>
        <Col span={4}>
          <i
            onClick={early ? () => setEarly(false) : () => setEarlyTrue()}
            aria-hidden="true"
            className={`fa fa-arrow-${early ? 'up' : 'down'} setting-${
              early ? 'default' : 'active'
            }-icon`}></i>
        </Col>
      </Row>

      <Row className={early ? 'setting-block' : 'setting-none'}>
        <SettingCarousel
          setItems={(array) => {
            setSymptonsIds(array);
          }}
          editable={true}
          data={symps.filter((a) => a.typeId === 3)}
        />
      </Row>

      <Row className="setting-row-padding">
        <Col span={20}>
          <text
            className={
              feelBetter ? 'setting-active-text' : 'setting-deactive-text'
            }>
            Feel Better
          </text>
        </Col>
        <Col span={4}>
          <i
            onClick={
              feelBetter
                ? () => setFeelBetter(false)
                : () => setFeelBetterTrue()
            }
            aria-hidden="true"
            className={`fa fa-arrow-${feelBetter ? 'up' : 'down'} setting-${
              feelBetter ? 'default' : 'active'
            }-icon`}></i>
        </Col>
      </Row>

      <Row className={feelBetter ? 'setting-block' : 'setting-none'}>
        <SettingCarousel
          setItems={(array) => {
            setSymptonsIds(array);
          }}
          editable={true}
          data={symps.filter((a) => a.typeId === 1)}
        />
      </Row>
    </>
  );
};

export default SymptomsBar;
