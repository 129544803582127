import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Select, Input, Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import io from 'socket.io-client';
import MultiSelect from 'react-multi-select-component';

import { Notification } from 'utils/notification';
import ChatDrawer from './components/Drawer';
import tags from 'apis/tag';
import messanger from 'apis/messenger';
import chat from 'apis/sendChatFile';
import './styles.css';

const { Option } = Select;
const socket = io('https://brze.info:9001');
const PublicMessenger = () => {
  const [messages, setMessages] = useState([]);
  const [children, setChidren] = useState([]);
  const [chosenChat, setChosenChat] = useState(null);
  const [title, setTitle] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [groups, setGroups] = useState([]);
  const [visible, setVisible] = useState(false);
  const [audienceType, setAudienceType] = useState();

  const getAllGroups = async () => {
    const response = await messanger.getGroups();
    setGroups(response);
  };
  const getAllTags = async () => {
    const response = await tags.getTags();
    setChidren(
      response.map((item) => {
        return {
          value: item.id,
          label: item.name,
          id: item.id,
        };
      }),
    );
  };
  const showDrawer = () => {
    return (
      <ChatDrawer
        visible={visible}
        setVisible={setVisible}
        messages={messages}
        setMessages={setMessages}
        chosenChat={chosenChat}
      />
    );
  };
  useEffect(() => {
    getAllGroups();
    getAllTags();
  }, []);

  const audienceMenu = (
    <>
      <Option value="0">Patient</Option>
      <Option value="1">Parent</Option>
      <Option value="2">Both</Option>
    </>
  );

  return (
    <>
      <Row justify="center" align="middle">
        <Col span={16}>
          <Row className="msg-row-1">
            <text className="msg-row-1-text">Public Messenger</text>
          </Row>

          <Row className="grp-block">
            {groups.reverse().map((item) => {
              return (
                <Row className="grp-row-1">
                  <Col span={4}>
                    <div className="drawer-row-2">
                      <text>{item.name.substr(0, 1).toUpperCase()}</text>
                    </div>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <text>{item.name}</text>
                    </Row>
                  </Col>
                  <Col span={10}></Col>
                  <Col span={4}>
                    <Tooltip title="Open Chat">
                      <RightOutlined
                        className="open-icon"
                        onClick={() => {
                          messanger
                            .getGroupsDetail(item.id)
                            .then((response) => {
                              setChosenChat({
                                ...response,
                                id: item.id,
                              });
                              setMessages([]);
                              const Token = JSON.parse(
                                localStorage.getItem('user'),
                              ).token;
                              socket.emit('clinicOnGlobalLine', {
                                token: Token,
                                toRole: 'patient',
                                groupId: item.id,
                              });
                              setVisible(true);
                              showDrawer();
                            });
                        }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              );
            })}
          </Row>
        </Col>
        <Col span={2} />
        <Col span={6} className="grp-form-container">
          <Form>
            <Form.Item>
              <Input
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                size="large"
                className="staff-input-typo-1 border-typo-1"
                placeholder="Title"
              />
            </Form.Item>
            <Form.Item>
              <Select
                className="select-1"
                placeholder="Select Audience"
                onChange={(e) => setAudienceType(parseInt(e))}>
                {audienceMenu}
              </Select>
            </Form.Item>
            <Form.Item>
              <MultiSelect
                options={children}
                value={selectedTags}
                onChange={(value) => setSelectedTags(value)}
                labelledBy={'Select'}
              />
            </Form.Item>
            <Button
              size="large"
              onClick={() => {
                chat
                  .addGroup({
                    tagIds: selectedTags,
                    title,
                    audience: audienceType,
                  })
                  .then(() => {
                    Notification(
                      'success',
                      'Group Has Been Created Successfully',
                    );
                    messanger.getGroups().then((response) => {
                      setGroups(response);
                      tags.getTags().then((response) => {
                        setChidren(
                          response.map((item) => {
                            return {
                              value: item.id,
                              label: item.name,
                              id: item.id,
                            };
                          }),
                        );
                      });
                    });
                    setSelectedTags([]);
                    setTitle('');
                    setAudienceType('');
                  });
              }}
              type="submit"
              className="btn-typo-1 border-typo-1">
              Create
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PublicMessenger;
