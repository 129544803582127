import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Row, Col, Select, Button, Input, Form } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import MultiSelect from 'react-multi-select-component';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Notification } from 'utils/notification';
import articlesAPI from 'apis/articles';
import categoriesAPI from 'apis/categories';
import tagsAPI from 'apis/tag';
import auth from 'apis/authentication';
import './styles.css';

const { Option } = Select;
const UpdateArticle = ({ selectedArticle, toggle }) => {
  const [html, setHtml] = useState('');
  const [title, setTitle] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [children, setChildren] = useState([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [audience, setAudience] = useState(null);
  const [types, setTypes] = useState([]);

  const inputFile = useRef(null);
  const onEditFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const res = await auth.upload(file);
    setImageUrl(res);
  };

  const loadCategories = async () => {
    const response = await categoriesAPI.getCategories();
    setTypes(
      response.articleTypes.map((item) => {
        return {
          key: item.id,
          name: item.name,
          value: item.id,
        };
      }),
    );
  };
  const getArticleCategories = async () => {
    const res = await categoriesAPI.getCategories();
    const artCategories = res.articleCategories.map((item) => {
      return <Option value={item.id}>{item.name}</Option>;
    });
    setArticleCategories(artCategories);
  };
  const getTags = async () => {
    const response = await tagsAPI.getTags();
    setChildren(
      response.map((item) => {
        return {
          value: item.id,
          label: item.name,
          parentId: item.parentId,
        };
      }),
    );
  };
  useEffect(() => {
    loadCategories();
    getArticleCategories();
    getTags();
  }, []);
  const audienceMenu = (
    <>
      <Option value="0">Patient</Option>
      <Option value="1">Parent</Option>
      <Option value="2">Both</Option>
    </>
  );
  return (
    <Fragment>
      <Col xs={24} md={4} lg={4} className="col-1-col-3">
        <input
          type={'file'}
          ref={inputFile}
          className="input-pick"
          onChange={onEditFile}
        />
        <img
          onClick={() => {
            inputFile.current.click();
          }}
          src={
            selectedArticle.image
              ? selectedArticle.image
              : imageUrl
              ? imageUrl
              : require('assets/313.png')
          }
          size="large"
          className="article-img-cover border-typo-1"
        />

        {imageUrl !== null && (
          <CopyToClipboard
            onCopy={() => {
              Notification(
                'success',
                'image link copied to clipboard successfully',
              );
            }}
            text={imageUrl}>
            <Button
              variant="light"
              className="btn-typo-1 border-typo-1 btn-margin">
              <span className="btn-span">Copy Image Link</span>
            </Button>
          </CopyToClipboard>
        )}
      </Col>
      <Col xs={1} md={1} lg={1} />
      <Col xs={24} md={12} lg={12} className="col-1-col-4">
        <Row>
          <Col md={12}>
            <Input
              placeholder={selectedArticle.title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              size="large"
              className="input-typo-1 border-typo-1 input-margin"
            />
          </Col>
          <Col md={12} />
        </Row>
        <Row>
          <Col md={24}>
            <Editor
              initialValue={
                selectedArticle !== null ? selectedArticle.text : ''
              }
              init={{
                height: 300,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | removeformat ',
              }}
              onEditorChange={(content) => {
                setHtml(content);
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={1} md={1} lg={1} />
      <Col xs={24} md={6} lg={6} className="row-1">
        <Form>
          <Form.Item>
            <Select
              className="select-1"
              placeholder="Select Category"
              onChange={(e) => {
                setSelectedCategory(parseInt(e));
              }}>
              {articleCategories}
            </Select>
          </Form.Item>
          <Form.Item>
            <Select
              className="select-1"
              placeholder="Select Type"
              onChange={(e) => {
                setSelectedType(parseInt(e));
              }}>
              {types.map((item) => (
                <Option value={item.key}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Select
              className="select-1"
              placeholder="Select Audience"
              onChange={(e) => {
                setAudience(parseInt(e));
              }}>
              {audienceMenu}
            </Select>
          </Form.Item>
          <Form.Item>
            <MultiSelect
              options={children}
              value={selectedTags}
              onChange={(value) => {
                setSelectedTags(value);
              }}
              labelledBy={'Select'}
            />
          </Form.Item>
          <Button
            size="large"
            disabled={
              !(
                selectedCategory !== null &&
                audience !== null &&
                selectedTags.length !== 0
              )
            }
            onClick={() => {
              articlesAPI
                .editArticle({
                  articleId: selectedArticle.id,
                  typeId: selectedType,
                  categoryId: selectedCategory,
                  title,
                  text: html,
                  image: imageUrl,
                  selectedTags,
                })
                .then(() => {
                  Notification('success', 'article edited successfully');
                  toggle();
                });
            }}
            type="submit"
            className={
              selectedCategory !== null &&
              audience !== null &&
              selectedTags.length !== 0
                ? 'btn-typo-1 border-typo-1'
                : 'btn-disabled-typo-1 border-typo-1'
            }>
            Save
          </Button>
        </Form>
      </Col>
    </Fragment>
  );
};

export default UpdateArticle;
