import React from 'react';
import './styles.css';

const NotFoundPage = (props) => {
  return (
    <div className="main-style">
      <img
        src={
          'https://i.pinimg.com/originals/86/41/80/86418032b715698a4dfa6684b50c12af.gif'
        }
      />
      <p className="p-style">
        <a
          onClick={() => {
            props.history.push('/home');
          }}>
          Go to Home
        </a>
      </p>
    </div>
  );
};
export default NotFoundPage;
