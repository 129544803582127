import React, { useState, useEffect } from 'react';
import { Row, Col, Tooltip } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Notification } from 'utils/notification';
import './styles.css';

const CustomCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      props.data.map((item) => {
        return {
          ...item,
          selection: false,
        };
      }),
    );
  }, []);

  const onClick = (_, index) => {
    let carouselData = data;
    if (props.editable) {
      if (carouselData.filter((item) => item.selection).length !== 5) {
        let temp = carouselData;
        let customData = carouselData[index];
        customData.selection = !customData.selection;
        temp[index] = customData;
        setData(temp);
        props.setItems(customData.id);
      } else {
        let temp = carouselData;
        let customData = carouselData[index];
        if (!customData.selection) {
          Notification('info', 'Maximum Item Selection');
        } else {
          customData.selection = !customData.selection;
          temp[index] = customData;
          setData(temp);
          props.setItems(customData.id);
        }
      }
    }
  };

  const left = () => {
    if (activeIndex !== 0) {
      setActiveIndex(activeIndex - 1);
    }
  };
  const Right = () => {
    if (activeIndex + 3 !== data.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };
  return (
    <Row justify="center" align="middle" className="carousel-row-1">
      <Col span={4}>
        <LeftOutlined
          onClick={() => {
            left();
          }}
          className="carousel-icon"
        />
      </Col>
      <Col span={16}>
        <Row>
          {data.slice(activeIndex, activeIndex + 3).map((item, index) => {
            return (
              <>
                <Col
                  span={7}
                  className={
                    item.selection
                      ? 'carousel-selected-item-div border-div-typo-2'
                      : 'carousel-item-div-main border-div-typo-2'
                  }
                  onClick={() => {
                    onClick(item, index + activeIndex);
                  }}>
                  <Tooltip title={item.name} placement="bottomLeft">
                    <img
                      src={item.imageLink}
                      alt="Card image"
                      className="carousel-img"
                    />
                  </Tooltip>
                </Col>
                <Col span={1}></Col>
              </>
            );
          })}
        </Row>
      </Col>
      <Col span={4}>
        <RightOutlined
          onClick={() => {
            Right();
          }}
          className="carousel-icon carousel-right-icon"
        />
      </Col>
    </Row>
  );
};

export default CustomCarousel;
