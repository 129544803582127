import React, { Fragment } from 'react';
import { Row, Col, Popover } from 'antd';
import { month } from 'constants/mock';

const Month = () => {
  return (
    <Fragment>
      {month.map((item) => {
        return (
          <Col span={2} style={{ paddingRight: '30px' }}>
            <Row className="month-text-1">
              <Col span={12} style={{ marginRight: '-50px' }}>
                <div
                  style={{
                    borderLeft: ' 6px solid #404040',
                    height: item.qa,
                    borderRadius: '3px',
                  }}
                />
              </Col>
              <Col span={12}>
                <div
                  style={{
                    borderLeft: ' 6px solid #fe8781',
                    height: item.sui,
                    borderRadius: '3px',
                  }}
                />
              </Col>
            </Row>
            <Row className="before-popover">
              <Popover
                content={
                  <Fragment>
                    <Row className="tooltip-row">
                      <Col span={16}>
                        <text className="popover-color">{item.name}</text>
                      </Col>
                      <Col span={8}>
                        <text className="popover-color">2020</text>
                      </Col>
                    </Row>
                    <Row className="tooltip-row">
                      <Col span={16}>
                        <text className="popover-color">Suicidal</text>
                      </Col>
                      <Col span={8}>
                        <text className="tooltip-data-row">{item.sui}</text>
                      </Col>
                    </Row>
                    <Row className="tooltip-row">
                      <Col span={16}>
                        <text className="popover-color">SelfHarm</text>
                      </Col>
                      <Col span={8}>
                        <text className="tooltip-data-row">{item.qa}</text>
                      </Col>
                    </Row>
                    <Row className="tooltip-width">
                      <Col span={16}>
                        <text className="popover-color">Stress Av</text>
                      </Col>
                      <Col span={8}>
                        <text className="tooltip-data-row">
                          {(item.qa + item.sui) / 2}
                        </text>
                      </Col>
                    </Row>
                  </Fragment>
                }
                background="white"
                placement="bottom"
                radius="10"
                border="#404040"
                fontSize="10px">
                <text>{item.name}</text>
              </Popover>
            </Row>
          </Col>
        );
      })}
    </Fragment>
  );
};

export default Month;
