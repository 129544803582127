import React, { Fragment, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Tooltip,
  Form,
  Input,
  DatePicker,
  Select,
} from 'antd';
import { UserOutlined, PlusOutlined, MailOutlined } from '@ant-design/icons';
import moment from 'moment';
import MultiSelect from 'react-multi-select-component';

import { Notification } from 'utils/notification';
import ImageBar from 'screens/Profiles/components/Image';
import SymptonsBar from 'screens/Profiles/components/Sypmtons';
import categories from 'apis/categories';
import symptoms from 'apis/clinic';
import tagApi from 'apis/tag';
import patients from 'apis/patient';
import './styles.css';

const { Option } = Select;
const CreatePatient = ({ toggle }) => {
  const [region, setRegion] = useState(null);
  const [regions, setRegions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [symps, setSymps] = useState([]);
  const [status, setStatus] = useState([]);
  const [phn, setPhn] = useState('');
  const [symIds, setSymIds] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [password, setPassword] = useState('');
  const [age, setAge] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState(null);
  const [tags, setTags] = useState(null);

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  const onChange = (dateString) => {
    setDateTime(dateString);
    var today = new Date();
    var birthDate = new Date(dateString);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    setAge(age_now);
  };

  const setSymptonsIds = (id) => {
    let temp = symIds;
    let index = temp.indexOf(id);
    if (index === -1) {
      setSymIds([...temp, id]);
    } else {
      setSymIds([...temp.filter((item) => item !== id)]);
    }
  };

  const getSymptopms = async () => {
    const response = await symptoms.getSymps();
    setSymps(response);
  };
  const getTags = async () => {
    const response = await tagApi.getTags();
    setTags(
      response.map((item) => {
        return { label: item.name, value: item.id };
      }),
    );
  };
  const getCategories = async () => {
    const res = await categories.getCategories();
    const states = res.states.map((item) => {
      return <Option value={item.id}>{item.name}</Option>;
    });
    setRegions(states);
  };

  useEffect(() => {
    getTags();
    getSymptopms();
    getCategories();
  }, []);

  const statusMenu = (
    <>
      <Option value="0">Male</Option>
      <Option value="1">Female</Option>
      <Option value="2">Other</Option>
    </>
  );
  return (
    <Row className="add-prof-row-1">
      <Col span={4}>
        <ImageBar
          image={image}
          setImage={(value) => {
            setImage(value);
          }}
        />
      </Col>
      <Col span={12}>
        <Form>
          <Row>
            <Col span={12}>
              <Form.Item>
                <Input
                  onChange={(e) => setFirstName(e.target.value)}
                  size="large"
                  className="staff-input-typo-1 border-typo-1"
                  placeholder="Name"
                  prefix={
                    <Fragment>
                      <UserOutlined className="mail-icon" /> &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Mail"
                  size="large"
                  className="staff-input-typo-1 border-typo-1"
                  prefix={
                    <Fragment>
                      <MailOutlined className="mail-icon" />
                      &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Row className="prof-row-2">
                <Col span={18}>
                  <Form.Item>
                    <DatePicker
                      disabledDate={disabledDate}
                      onChange={onChange}
                      placeholder="Select Birthdate"
                      size="large"
                      className="staff-input-typo-1 border-typo-1"
                    />
                  </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={4} className="age-col">
                  <Form.Item>
                    <Tooltip title="Age">
                      <Input
                        disabled
                        value={age}
                        size="large"
                        className="staff-input-typo-1 border-typo-1"
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <MultiSelect
                  options={tags !== null ? tags : [1, 2, 3]}
                  value={selected}
                  onChange={setSelected}
                  labelledBy={'Select'}
                  className="multi-select"
                  placeholder="Select Tags"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Select
                  className="select-1"
                  placeholder="Select Region"
                  onChange={(e) => setRegion(parseInt(e))}>
                  {regions}
                </Select>
              </Form.Item>
              <Form.Item>
                <Select
                  className="select-1"
                  placeholder="Select Status"
                  onChange={(e) => setStatus(parseInt(e))}>
                  {statusMenu}
                </Select>
              </Form.Item>
              <Form.Item>
                <Input
                  size="large"
                  className="staff-input-typo-1 border-typo-1"
                  onChange={(e) => setPhn(e.target.value)}
                  placeholder="PHN"
                  prefix={
                    <Fragment>
                      <PlusOutlined className="mail-icon" /> &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  type="password"
                  size="large"
                  className="staff-input-typo-1 border-typo-1"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  prefix={
                    <Fragment>
                      <i
                        aria-hidden="true"
                        className="fa fa-shield mail-icon"
                      />{' '}
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={8} className="prof-last-col">
        <Col span={18}>
          <SymptonsBar
            setSymptonsIds={(id) => setSymptonsIds(id)}
            symps={symps}
          />
          <Row>
            <Button
              size="large"
              onClick={() => {
                patients
                  .addPatient({
                    firstname: firstName,
                    birthdate: dateTime,
                    gender: status,
                    email,
                    password,
                    image,
                    symptomIds: symIds,
                    phn,
                    age,
                    region,
                    selected,
                  })
                  .then(() => {
                    Notification(
                      'success',
                      'Patient Profile Created Successfully',
                    );
                    patients.getPatients().then((response) => {
                      patients
                        .getPatientPmBadges(
                          JSON.parse(localStorage.getItem('user')).clinicId,
                        )
                        .then((res) => {
                          toggle(false);
                        });
                    });
                  });
              }}
              className="btn-typo-1 border-typo-1">
              Create
            </Button>
          </Row>
          <Col span={6} />
        </Col>
      </Col>
    </Row>
  );
};

export default CreatePatient;
