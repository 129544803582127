import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Tooltip, Popconfirm } from 'antd';
import { DisconnectOutlined } from '@ant-design/icons';
import ClockLoader from 'react-spinners/ClockLoader';
import { useHistory } from 'react-router-dom';

import { Notification } from 'utils/notification';
import colors from 'constants/colors';
import { panes } from 'constants/mock';
import Pdf from './components/Excel/index';
import ChatDrawer from './components/Drawer';
import TodoList from 'screens/Patient/components/TodoList';
import Settings from 'screens/Patient/components/Setting';
import QA from 'screens/Patient/components/QA';
import Info from 'screens/Patient/components/Info';
import staff from 'apis/staff';
import patient from 'apis/patient';
import './styles.css';

import { css } from '@emotion/core';
const override = css`
  display: block;
  border-color: #fe8781;
  margin-top: 10%;
  margin-left: 50%;
`;

const SinglePatient = (props) => {
  const [load, setLoad] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [info, setInfo] = useState(null);
  const [visible, setVisible] = useState(false);

  const history = useHistory();

  const activeView = () => {
    if (activeIndex === 0)
      return (
        <Info
          info={info}
          load={() => {
            setLoad(false);
          }}
        />
      );
    else if (activeIndex === 1)
      return <TodoList reload={() => getDetail()} info={info} />;
    else if (activeIndex === 2) return <QA info={info} />;
    return (
      <Settings
        load={() => {
          getDetail();
        }}
        info={info}
      />
    );
  };

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const response = await patient.getPatientDetail(props.location.state.id);
    setInfo(response);
  };

  return (
    <Fragment>
      {load === false ? (
        <Fragment>
          {visible ? (
            <ChatDrawer
              userId={props.location.state.id}
              load={load}
              setLoad={setLoad}
              visible={visible}
              setVisible={setVisible}
            />
          ) : null}
          <Row className="row-padding">
            <Col className="single-row-1" span={10}>
              <Row>
                <Tooltip title="Back To Patient List">
                  <Col span={2}>
                    <i
                      onClick={() => {
                        history.push('/profiles');
                        window.location.reload();
                      }}
                      className="fa fa-arrow-left i-icon"
                      aria-hidden="true"
                    />
                  </Col>
                </Tooltip>
                <Col span={8} className="single-col-2">
                  {info !== null && (
                    <text className="single-col-2-text">{info.firstname}</text>
                  )}
                </Col>
                <Col span={14}>
                  <text className="off-text">
                    {info.official === true ? 'Offical' : null}
                  </text>
                </Col>
              </Row>
            </Col>
            <Col className="single-col-3" span={8}>
              <Row className="single-row-3">
                {panes.map((item) => {
                  return (
                    <Col span={6} className="center-cont">
                      <Row className="center-cont">
                        <a
                          onClick={() => {
                            setActiveIndex(item.key);
                          }}
                          className={
                            activeIndex === item.key
                              ? 'selected-pane'
                              : 'deselected-pane'
                          }>
                          {item.name}
                        </a>
                      </Row>
                      <Row className="center-cont">
                        {activeIndex === item.key ? (
                          <div className="underline-div" />
                        ) : null}
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col className="center-cont" span={6}>
              <Row className="single-row-4">
                {info.panic === true ? (
                  <Tooltip title="Panic Mode Is On">
                    <Col span={4}>
                      <i
                        aria-hidden="true"
                        className="fa fa-heart-o heart-icon"></i>
                    </Col>
                  </Tooltip>
                ) : (
                  <Tooltip title="Panic Mode Is Off">
                    <Col span={4}>
                      <i
                        aria-hidden="true"
                        className="fa fa-heart-o sqaure-icon"></i>
                    </Col>
                  </Tooltip>
                )}
                {info.parentId !== null ? (
                  <Tooltip title="Parents Connected">
                    <Col span={4}>
                      <i
                        aria-hidden="true"
                        className="fa fa-square-o heart-icon"></i>
                    </Col>
                  </Tooltip>
                ) : (
                  <Tooltip title="Parents Not Connected">
                    <Col span={4}>
                      <i
                        aria-hidden="true"
                        className="fa fa-square-o sqaure-icon"></i>
                    </Col>
                  </Tooltip>
                )}
                <Tooltip title="Chat">
                  <Col span={4}>
                    <i
                      aria-hidden="true"
                      className="fa fa-file-text-o sqaure-icon"
                      onClick={() => setVisible(true)}></i>
                  </Col>
                </Tooltip>
                <Tooltip title="Report">
                  <Col span={4}>
                    <Pdf id={props.location.state.id} patientInfo={info} />
                  </Col>
                </Tooltip>
                <Popconfirm
                  onConfirm={() => {
                    staff.activateStaff(info.id).then(() => {
                      Notification(
                        'success',
                        'Patient Deactivated Successfully',
                      );
                      props.history.push({
                        pathname: '/profiles',
                      });
                    });
                  }}
                  okText="Yes"
                  title="Are you sure to disable this patient？"
                  icon={<DisconnectOutlined className="heart-icon" />}>
                  <Tooltip title="Disable">
                    <Col span={4}>
                      <i
                        aria-hidden="true"
                        className="fa fa-trash-o heart-icon"></i>
                    </Col>
                  </Tooltip>
                </Popconfirm>
              </Row>
            </Col>
          </Row>
          {info !== null && activeView()}
        </Fragment>
      ) : (
        <div>
          <ClockLoader
            css={override}
            size={50}
            color={colors.base}
            loading={load}
          />
          {info !== null && activeView()}
        </div>
      )}
    </Fragment>
  );
};

export default SinglePatient;
