import React, { Fragment, useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';

import { Notification } from 'utils/notification';
import auth from 'apis/authentication';
import './styles.css';

const ForgotPass = (props) => {
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);

  const resetPass = async () => {
    if (password === passwordConfirmation) {
      try {
        await auth.resetPass(props.match.params.mail, password);
        props.history.push('/login');
      } catch (error) {
        Notification('error', error);
      }
    } else {
      Notification('error', 'Password and Password confirmation do not match');
      setPassword(null);
      setPasswordConfirmation(null);
    }
  };
  return (
    <Row>
      <Col md={14} className="auth-row-1">
        <img className="auth-image" src={require('./../../assets/doc.png')} />
      </Col>

      <Col md={10} className="auth-col-1">
        <Row>
          <text className="auth-col-text-2">
            Welcome {props.match.params.mail}
          </text>
        </Row>
        <Row className="auth-row-2">
          <text className="auth-row-2-text">
            Reset your password to continue
          </text>
        </Row>
        <Row className="auth-row-3">
          <Form>
            <Form.Item>
              <Input.Password
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                size="large"
                className="auth-input border-typo-1"
                placeholder="Password"
                prefix={
                  <Fragment>
                    <i
                      className="fa fa-shield article-icon"
                      aria-hidden="true"></i>{' '}
                    &nbsp;&nbsp;&nbsp;
                  </Fragment>
                }
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                rules={[
                  {
                    required: true,
                    message: 'Please input your password Confirmation!',
                  },
                ]}
                onChange={(e) => {
                  setPasswordConfirmation(e.target.value);
                }}
                size="large"
                className="auth-input border-typo-1"
                placeholder="Password Confirmation"
                prefix={
                  <Fragment>
                    <i
                      className="fa fa-shield article-icon"
                      aria-hidden="true"></i>{' '}
                    &nbsp;&nbsp;&nbsp;
                  </Fragment>
                }
              />
            </Form.Item>
            <Form.Item style={{ width: '100%' }}>
              <Button
                size="large"
                onClick={() => resetPass()}
                disabled={
                  passwordConfirmation === null ||
                  password === null ||
                  passwordConfirmation === '' ||
                  password === ''
                }
                style={{
                  width: '100%',
                  backgroundColor:
                    passwordConfirmation === null ||
                    password === null ||
                    passwordConfirmation === '' ||
                    password === ''
                      ? '#404040'
                      : '#fe8781',
                  borderWidth: 0,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  borderBottomLeftRadius: 10,
                  paddingLeft: '5px',
                  color: '#fff',
                }}
                type="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

export default ForgotPass;
