import React, { useState, useEffect } from 'react';
import { Row, Col, Tooltip } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { ToastContainer } from 'react-toastify';
import ClockLoader from 'react-spinners/ClockLoader';
import ReactSearchBox from 'react-search-box';

import CreatePatient from 'screens/Profiles/components/Create';
import PatientTable from 'screens/Profiles/components/Table';
import patients from 'apis/patient';
import colors from 'constants/colors';
import './styles.css';

import { css } from '@emotion/core';
const override = css`
  display: block;
  border-color: #fe8781;
  margin-top: 10%;
  margin-left: 50%;
`;

const PatientProfiles = (props) => {
  const [load, setLoad] = useState(true);
  const [usersBadges, setUsersBadges] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [createPatient, setCreatePatient] = useState(false);

  const push = (id) => {
    props.history.push({
      pathname: '/info',
      state: { id: id },
    });
  };
  const getPatients = async () => {
    const response = await patients.getPatients();
    setUsers(response);
  };
  const getPatientInfo = async () => {
    const pmBadges = await patients.getPatientPmBadges(
      JSON.parse(localStorage.getItem('user')).clinicId,
    );
    setUsersBadges(pmBadges);
    setLoad(false);
  };
  useEffect(() => {
    getPatients();
    getPatientInfo();
  }, []);

  return (
    <Col span={24} className="prof-col-1">
      <ToastContainer
        onClose={() => {
          alert('as');
        }}
        className="toast-cont"
      />
      {!load && (
        <Row className="prof-row-1">
          <Col span={8}>
            {createPatient ? (
              <Row>
                <Col md={2} lg={2} xs={2}>
                  <Tooltip title="Back To Patient List">
                    <i
                      onClick={() => {
                        setCreatePatient(!createPatient);
                      }}
                      className="fa fa-arrow-left back-icon"
                      aria-hidden="true"
                    />
                  </Tooltip>
                </Col>
                <Col md={22} lg={22} xs={22} className="prof-header">
                  <text className="prof-header-text">Create Profile</text>
                </Col>
              </Row>
            ) : (
              <text className="prof-header-text">Patient Profiles</text>
            )}
          </Col>
          <Col span={14}>
            {createPatient ? null : (
              <Row>
                <Col span={1}>
                  <SearchOutlined className="search-icon" />
                </Col>
                <Col span={23}>
                  <ReactSearchBox
                    placeholder="Search"
                    inputBoxBorderColor={'#fff'}
                    onChange={(text) => {
                      setSearchText(text);
                      setSearchUsers(
                        users.find((item) => {
                          item.firstname.includes(text) ||
                            item.lastname.includes(text);
                        }),
                      );
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>

          <Col span={2}>
            {createPatient ? null : (
              <Tooltip title="Add Profile">
                <PlusOutlined
                  className="prof-plus-icon"
                  onClick={() => {
                    setCreatePatient(!createPatient);
                  }}
                />
              </Tooltip>
            )}
          </Col>
        </Row>
      )}

      {createPatient ? (
        <CreatePatient toggle={setCreatePatient} />
      ) : load === false ? (
        <Row className="show-staff-row-1" justify="center" align="middle">
          <Col span={24}>
            <PatientTable
              data={searchText ? searchUsers : users}
              badges={usersBadges}
              push={(id) => push(id)}
            />
            {console.log(users)}
          </Col>
        </Row>
      ) : (
        <div>
          <ClockLoader
            css={override}
            size={50}
            color={colors.base}
            loading={load}
          />
        </div>
      )}
    </Col>
  );
};

export default PatientProfiles;
