import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';

export default {
  getTags: () => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/tags',
      headers: ApiHeader,
    });
  },
};
