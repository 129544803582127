import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Drawer, Input } from 'antd';
import { PaperClipOutlined, SmileOutlined } from '@ant-design/icons';
import { ChatFeed, ChatBubble } from 'react-bell-chat';
import io from 'socket.io-client';
import Picker from 'emoji-picker-react';

import { authors } from 'constants/mock';
import messanger from 'apis/sendChatFile';
import './styles.css';

const socket = io('https://brze.info:9001');
const ChatDrawer = ({ userId, load, setLoad, visible, setVisible }) => {
  const [emojyPanel, setEmojyPanel] = useState(false);
  const [offset, setOffset] = useState(0);
  const [toRole, setToRole] = useState('patient');
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const inputFile = useRef(null);
  const Token = JSON.parse(localStorage.getItem('user')).token;

  const drawerTitle = () => {
    return (
      <Row justify="center" align="middle">
        <Col span={12}>
          <Row className="drawer-title-row-1">
            <div
              className="row-1-div"
              onClick={() => {
                if (toRole !== 'patient') {
                  setToRole('patient');
                  setOffset(0);
                  setMessages([]);
                  socket.emit('clinicOnLine', {
                    token: Token,
                    toRole: 'patient',
                    userId: userId,
                  });
                }
              }}>
              <img
                src={
                  JSON.parse(localStorage.getItem('user')).image == null
                    ? 'https://www.jumpstarttech.com/files/2018/08/Network-Profile.png'
                    : JSON.parse(localStorage.getItem('user')).image
                }
                className="drawer-header-chat-img"
              />
            </div>
          </Row>
          <Row className="drawer-row-4">
            &nbsp;
            <text
              className={
                toRole === 'patient' ? 'to-patient' : 'out-of-patient'
              }>
              Child
            </text>
          </Row>
        </Col>
        <Col span={12}>
          <Row className="drawer-row-5">
            <div
              className="row-5-div"
              onClick={() => {
                if (toRole !== 'parent') {
                  setToRole('parent');
                  setOffset(0);
                  setMessages([]);
                  socket.emit('clinicOnLine', {
                    token: Token,
                    toRole: 'parent',
                    userId: userId,
                  });
                }
              }}>
              <img
                src={
                  JSON.parse(localStorage.getItem('user')).image == null
                    ? 'https://www.jumpstarttech.com/files/2018/08/Network-Profile.png'
                    : JSON.parse(localStorage.getItem('user')).image
                }
                className="drawer-header-chat-img"
              />
            </div>
          </Row>
          <Row className="drawer-row-6">
            &nbsp;
            <text
              className={toRole === 'parent' ? 'to-parent' : 'out-of-parent'}>
              Parent
            </text>
          </Row>
        </Col>
      </Row>
    );
  };
  const sendMessage = () => {
    let payload = {
      token: Token,
      toId: userId,
      message: input.length > 10 ? input : '        ' + input,
      type: toRole,
    };
    socket.emit('onClinicPm', JSON.stringify(payload));
    setInput('');
  };
  useEffect(() => {
    socket.emit('clinicOnLine', {
      token: Token,
      toRole: 'patient',
      userId: userId,
    });
  }, []);
  useEffect(() => {
    socket.on('answer', (data) => {
      switch (data.type) {
        case 'onClinicOnline':
          let serverMessages = data.data;
          let normlizedMessages = [];
          serverMessages.forEach((message) => {
            normlizedMessages.push({
              id: message._id,
              authorId: message.toRole === 'clinic' ? 1 : 2,
              message: message.message,
              createdOn: new Date(message.createdAt),
              isSend: true,
              fileHash: message.fileHash,
              toRole: message.toRole,
              messageType: message.messageType,
              seenStatus: message.seenStatus,
              deliverStatus: message.deliverStatus,
            });
          });

          setMessages([...normlizedMessages.reverse(), ...messages]);

          break;
        case 'newMessage':
          if (
            messages.filter((item) => item.id === data.data._id).length === 0
          ) {
            let Messages = messages;
            Messages.push({
              id: data.data._id,
              authorId: data.data.toRole === 'clinic' ? 1 : 2,
              message: data.data.message,
              createdOn: new Date(data.data.createdAt),
              isSend: true,
              fileHash: data.data.fileHash,
              toRole: data.data.toRole,
              messageType: data.data.messageType,
              seenStatus: data.data.seenStatus,
              deliverStatus: data.data.deliverStatus,
            });
            setMessages(Messages);
          }

          break;
        default:
          break;
      }
    });
  }, [messages]);
  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];

    messanger.sendChatFile({ file, toRole, toId: userId }).then((response) => {
      let temp = messages;
      temp.push({
        id: response._id,
        authorId: response.toRole === 'clinic' ? 1 : 2,
        message: response.message,
        createdOn: new Date(response.createdAt),
        isSend: true,
      });

      setMessages(temp);
    });
  };
  return (
    <Drawer
      title={drawerTitle()}
      placement="right"
      closable={false}
      onClose={() => setVisible(false)}
      visible={visible}
      width={400}
      headerStyle={{
        borderBottomRightRadius: 50,
        height: '20%',
        boxShadow: '2px 2px 4px #f4f6f8',
      }}
      drawerStyle={{ borderBottomLeftRadius: '50px' }}>
      <ChatFeed
        messages={messages}
        className={'chat'}
        authors={authors}
        yourAuthorId={2}
        showLoadingMessages={load}
        hasOldMessages={true}
        loadOldMessagesThreshold={10}
        showDateRow={true}
        onLoadOldMessages={() => {
          setLoad(true);
          socket.emit('clinicOnLine', {
            token: Token,
            toRole: toRole,
            offset: offset,
            userId: userId,
          });
          setOffset(offset + 10);
          setLoad(false);
        }}
        customChatBubble={(message) => {
          if (message.message.messageType === 'file') {
            return (
              <Row>
                <Col span={8}>
                  <text> </text>
                  {message.message.toRole === 'clinic' && (
                    <a
                      href={
                        'https://brze.info:7273/file/download/' +
                        message.message.fileHash +
                        '?token=' +
                        Token
                      }
                      download>
                      <div className="drawer-header-img">
                        <text className="fff-text">
                          {message.message.fileHash.split('.')[1]} File
                        </text>
                      </div>
                    </a>
                  )}
                </Col>
                <Col span={8} />
                <Col span={8}>
                  <text> </text>
                  {message.message.toRole !== 'clinic' && (
                    <a
                      href={
                        'https://brze.info:7273/file/download/' +
                        message.message.fileHash +
                        '?token=' +
                        Token
                      }
                      download>
                      <div className="drawer-header-img">
                        <text className="fff-text">
                          {message.message.fileHash.split('.')[1]} File
                        </text>
                      </div>
                    </a>
                  )}
                </Col>
              </Row>
            );
          } else {
            return <ChatBubble message={message.message} yourAuthorId={2} />;
          }
        }}
        showRecipientAvatar={false}
        showRecipientLastSeenMessage={false}
      />
      {emojyPanel && (
        <Picker
          onEmojiClick={(_, emojiObject) => setInput(input + emojiObject.emoji)}
        />
      )}
      <div className="input-container">
        <Input
          value={input}
          onPressEnter={sendMessage}
          onChange={(a) => {
            setInput(a.target.value);
          }}
          size="large"
          placeholder="Type Here ..."
          className="drawer-input-cont"
        />

        <input
          type={'file'}
          ref={inputFile}
          className="input-pick"
          onChange={onChangeFile}
        />
        <a
          onClick={() => {
            inputFile.current.click();
          }}>
          <PaperClipOutlined className="drawer-icon" />
        </a>

        <a
          onClick={() => {
            setEmojyPanel(!emojyPanel);
          }}>
          <SmileOutlined className="drawer-icon" />
        </a>
      </div>
    </Drawer>
  );
};

export default ChatDrawer;
