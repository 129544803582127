import React, { Fragment, useRef, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Tooltip,
  Select,
} from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import moment from 'moment';

import { Notification } from 'utils/notification';
import staff from 'apis/staff';
import auth from 'apis/authentication';
import './styles.css';

const { Option } = Select;
const CreateClinic = (props) => {
  const [age, setAge] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [name, setName] = useState(null);
  const [image, setImage] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [gender, setGender] = useState(null);

  const inputFile = useRef(null);
  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    auth.upload(file).then((res) => {
      setImage(res);
    });
  };

  const handleGenderChange = (e) => {
    setGender(parseInt(e));
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  const onChange = (dateString) => {
    setDateTime(dateString);
    var today = new Date();
    var birthDate = new Date(dateString);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    setAge(age_now);
  };
  const menu = (
    <>
      <Option value="1">Male</Option>
      <Option value="2">Female</Option>
      <Option value="3">Other</Option>
    </>
  );
  return (
    <Row className="staff-row-1">
      <Col xs={24} md={4} lg={4} className="staff-row-1-col-1">
        <input
          type={'file'}
          ref={inputFile}
          className="input-pick"
          onChange={onChangeFile}
        />
        <Tooltip title="Click To Upload Or Change Image" placement="bottom">
          <img
            onClick={() => {
              inputFile.current.click();
            }}
            src={
              image !== null
                ? image
                : 'https://www.duffyduffylaw.com/wp-content/uploads/2018/05/user-placeholder-200x250.jpg'
            }
            size="large"
            className="staff-img border-typo-1"
          />
        </Tooltip>
      </Col>
      <Col xs={24} md={20} lg={20} className="staff-col-2">
        <Form>
          <Row>
            <Col md={8} lg={8} xs={24}>
              <Form.Item>
                <Input
                  size="large"
                  className="staff-input-typo-1 border-typo-1"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Name"
                  prefix={
                    <Fragment>
                      <UserOutlined className="mail-icon" /> &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  size="large"
                  className="staff-input-typo-1 border-typo-1"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                  prefix={
                    <Fragment>
                      <MailOutlined className="mail-icon" /> &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Row className="staff-row-2">
                <Col md={18} lg={18} xs={18}>
                  <Form.Item>
                    <DatePicker
                      disabledDate={disabledDate}
                      onChange={onChange}
                      placeholder="Select Birthdate"
                      size="large"
                      className="staff-input-typo-1 border-typo-1"
                    />
                  </Form.Item>
                </Col>
                <Col md={5} lg={5} xs={5} className="staff-row-2-col-1">
                  <Form.Item>
                    <Tooltip title="Age">
                      <Input
                        disabled
                        value={age}
                        size="large"
                        className="staff-input-typo-1 border-typo-1"
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>

              <Select
                className="select-1"
                placeholder="Select Status"
                onChange={handleGenderChange}>
                {menu}
              </Select>
            </Col>
            <Col md={8} lg={8} xs={24}>
              <Form.Item>
                <Input
                  size="large"
                  className="staff-input-typo-1 border-typo-1"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  placeholder="Phone"
                  prefix={
                    <Fragment>
                      <PhoneOutlined className="mail-icon" /> &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  type="password"
                  size="large"
                  className="staff-input-typo-1 border-typo-1"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Password"
                  prefix={
                    <Fragment>
                      <i
                        className="fa fa-shield mail-icon"
                        aria-hidden="true"></i>{' '}
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  type="password"
                  size="large"
                  className="staff-input-typo-1 border-typo-1"
                  placeholder="Password Confirmation"
                  prefix={
                    <Fragment>
                      <i
                        aria-hidden="true"
                        className="fa fa-shield mail-icon"></i>{' '}
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Button
                onClick={() => {
                  staff
                    .addStaff({
                      name,
                      lastname: null,
                      dateTime,
                      gender,
                      phoneNumber,
                      email,
                      password,
                      image,
                      age,
                    })
                    .then(() => {
                      Notification('success', 'Clinician added successfully');
                      props.change();
                      props.get();
                    });
                }}
                variant="light"
                className="btn-typo-1 border-typo-1">
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default CreateClinic;
