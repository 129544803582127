import React, { Fragment } from 'react';
import { Row, Col, Popover } from 'antd';
import moment from 'moment';

const Left = ({ data, sex }) => {
  const getDate = (date) => {
    if (date !== 'no data') {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return startDate.format('dddd').substring(0, 3);
    }
    return <text className="no-data-text">No Data</text>;
  };
  const getTime = (date) => {
    if (date !== 'no data') {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return `${startDate.format('D')} ${startDate.format('MMMM')}`;
    }
    return <text className="no-data-right">No Data</text>;
  };
  return (
    <>
      <Row justify="center" className="info-row-2">
        {data.map((item) => {
          return (
            <Popover
              content={
                <Fragment>
                  <Row className="tip-width">
                    <Col span={12}>
                      <text className="tip-left-text">
                        {getDate(item.datetime)}
                      </text>
                    </Col>
                    <Col span={12}>
                      <text className="tip-right-text">
                        {getTime(item.datetime)}
                      </text>
                    </Col>
                  </Row>
                  <Row className="tip-width">
                    <Col span={16}>
                      <text className="tip-data-left">Panic</text>
                    </Col>
                    <Col span={8}>
                      <text className="tip-data-right">{item.totalPanics}</text>
                    </Col>
                  </Row>
                  <Row className="tip-width">
                    <Col span={16}>
                      <text className="tip-data-left">QA</text>
                    </Col>
                    <Col span={8}>
                      <text className="tip-data-right">{item.totalQA}</text>
                    </Col>
                  </Row>
                  <Row className="tip-width">
                    <Col span={16}>
                      <text className="tip-data-left">Stress Av</text>
                    </Col>
                    <Col span={8}>
                      <text className="tip-data-right">{item.stress}</text>
                    </Col>
                  </Row>
                </Fragment>
              }
              background="white"
              placement="bottom"
              radius="10"
              border="#404040"
              fontSize="10px">
              <Col span={4} className="right-side-tip">
                <Row
                  style={{
                    borderLeft: ' 6px solid #404040',
                    height: 100 - parseInt(item.stress),
                    borderRadius: '3px',
                    margin: '0px',
                  }}
                />
                <Row
                  style={{
                    borderLeft: ' 6px solid #FE8781',
                    height: parseInt(item.stress),
                    borderRadius: '3px',
                    margin: '0px',
                  }}
                />
              </Col>
            </Popover>
          );
        })}
      </Row>
      <Row justify="center">
        <Col>
          <Row justify="center">
            <text className="user-info">{sex}</text>
          </Row>
          <Row justify="center">
            <text className="user-data">Sex</text>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Left;
