import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Tooltip, Select } from 'antd';
import { ToastContainer } from 'react-toastify';
import { CheckCircleTwoTone } from '@ant-design/icons';

import { Notification } from 'utils/notification';
import task from 'apis/task';
import './styles.css';

const { Option } = Select;
const TodoList = ({ info }) => {
  const [activeIndex, setActiveIndex] = useState(false);
  const [text, setText] = useState(null);
  const [toDoType, setTodoType] = useState(null);
  const [audienceType, setAudienceType] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [parentTasks, setParentTasks] = useState([]);

  const getBoth = async () => {
    const response = await task.getBothTasks(info.id);
    if (response.parentTasks) setParentTasks(response.parentTasks);
    if (response.patientTasks) setTasks(response.patientTasks);
  };

  useEffect(() => {
    if (info.tasks) setTasks(info.tasks);
    if (info.parentTasks) setParentTasks(info.parentTasks);
  }, []);

  const taskTypes = (typeIds) => {
    if (typeIds === 1) return <text className="to-patient">Daily</text>;
    else if (typeIds === 2) return <text className="to-patient">Weekly</text>;
    return <text className="to-patient">Monthly</text>;
  };

  const menu = (
    <>
      <Option value="1">Daily</Option>
      <Option value="2">Weekly</Option>
      <Option value="3">Monthly</Option>
    </>
  );
  const audienceMenu = (
    <>
      <Option value="0">Patient</Option>
      <Option value="1">Parent</Option>
      <Option value="2">Both</Option>
    </>
  );

  return (
    <Fragment>
      <Row className="first-row-padding">
        <Col span={4} />
        <Col span={4} className="center-cursor">
          <Row className="center-cont">
            <a
              onClick={() => {
                setActiveIndex(true);
              }}
              className={activeIndex ? 'deactive-pane-row' : 'active-pane-row'}>
              Parent Todos
            </a>
          </Row>
          <Row className="center-cont">
            {activeIndex === true ? <div className="pane-div" /> : null}
          </Row>
        </Col>
        <Col span={4} className="center-cursor">
          <Row className="center-cont">
            <a
              onClick={() => {
                setActiveIndex(false);
              }}
              className={
                !activeIndex ? 'deactive-pane-row' : 'active-pane-row'
              }>
              Patient Todos
            </a>
          </Row>
          <Row className="center-cont">
            {activeIndex === false ? <div className="pane-div" /> : null}
          </Row>
        </Col>
        <Col span={12} />
      </Row>
      <Row className="first-row-padding">
        <ToastContainer
          onClose={() => {
            alert('as');
          }}
          className="toast-container-width"
        />
        <Col span={16} className="todos-div">
          {!activeIndex && tasks.length === 0 ? (
            <text className="no-todos">No Item Avaiable</text>
          ) : null}
          {activeIndex && parentTasks.length === 0 ? (
            <text className="no-todos">No Item Avaiable</text>
          ) : null}
          {!activeIndex &&
            tasks.map((item) => {
              return (
                <Row>
                  <Col span={4}></Col>
                  <Col span={18}>
                    <Row className="task-row">
                      <Col span={10} className="col-align">
                        <text className="task-text">{item.text}</text>
                      </Col>
                      <Col span={4}>
                        {item.isForce ? (
                          <text className="force-text">Force</text>
                        ) : null}
                      </Col>
                      <Col span={4}>
                        {item.doneStatus ? (
                          <Tooltip title="Task Done">
                            <CheckCircleTwoTone
                              className="task-icon"
                              twoToneColor="#52c41a"
                            />
                          </Tooltip>
                        ) : null}
                      </Col>
                      <Col span={4}>{taskTypes(item.typeId)}</Col>
                      <Col span={2}>
                        <i
                          aria-hidden="true"
                          className="fa fa-trash-o trash-task-icon"
                          onClick={() => {
                            task.deleteTask(item.id).then((response) => {
                              Notification('info', 'Task Deleted Successfully');
                              getBoth();
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          {activeIndex &&
            parentTasks.map((item) => {
              return (
                <Row>
                  <Col span={4}></Col>
                  <Col span={18}>
                    <Row className="task-row">
                      <Col span={10} className="col-align">
                        <text className="task-text">{item.text}</text>
                      </Col>
                      <Col span={4}>
                        {item.isForce ? (
                          <text className="force-text">Force</text>
                        ) : null}
                      </Col>
                      <Col span={4}>
                        {item.doneStatus ? (
                          <Tooltip title="Task Done">
                            <CheckCircleTwoTone
                              className="task-icon"
                              twoToneColor="#52c41a"
                            />
                          </Tooltip>
                        ) : null}
                      </Col>
                      <Col span={4}>{taskTypes(item.typeId)}</Col>
                      <Col span={2}>
                        <i
                          aria-hidden="true"
                          className="fa fa-trash-o trash-style-icon"
                          onClick={() => {
                            task.deleteTask(item.id).then((response) => {
                              Notification('info', 'Task Deleted Successfully');
                              getBoth();
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
        </Col>
        <Col span={2}></Col>
        <Col span={6} className="todo-form">
          <Form>
            <Form.Item>
              <Input
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                size="large"
                className="todo-input-typo-2 border-typo-2"
                placeholder="Title"
              />
            </Form.Item>
            <Form.Item>
              <Select
                className="select-3"
                placeholder="Select Time Range"
                onChange={(e) => setTodoType(parseInt(e))}>
                {menu}
              </Select>
            </Form.Item>
            <Form.Item>
              <Select
                className="select-3"
                placeholder="Select Audience"
                onChange={(e) => setAudienceType(parseInt(e))}>
                {audienceMenu}
              </Select>
            </Form.Item>
            <Button
              size="large"
              onClick={() => {
                if (text && toDoType) {
                  task
                    .addTask({
                      userId: info.id,
                      typeId: toDoType,
                      text,
                      isForce: false,
                      audience: audienceType,
                    })
                    .then(() => {
                      Notification('success', 'Task Created Successfully');
                      getBoth();
                      setTodoType(null);
                      setAudienceType(null);
                      setText(null);
                    });
                }
              }}
              disabled={!(text && toDoType)}
              type="submit"
              className={
                text && toDoType
                  ? 'todo-btn-typo-2 border-typo-2'
                  : 'todo-btn-disabled-typo-2 border-typo-2'
              }>
              Set
            </Button>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TodoList;
