import React, { Fragment, useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import { Notification } from 'utils/notification';
import auth from 'apis/authentication';
import './styles.css';

const ForgotPassRequest = () => {
  const [email, setEmail] = useState(null);

  const forgotPassReq = async () => {
    if (email !== null) {
      try {
        await auth.resetPassRequest(email);
        setEmail(null);
        Notification(
          'success',
          'Check your email also your spam section to reset your password',
        );
      } catch (error) {
        Notification('error', error);
      }
    } else {
      Notification('error', 'Email should not be empty');
      setEmail(null);
    }
  };
  return (
    <Row>
      <Col md={14} className="auth-row-1">
        <img className="auth-image" src={require('./../../assets/doc.png')} />
      </Col>

      <Col md={10} className="auth-col-1">
        <Row>
          <text className="auth-col-text-3">Welcome</text>
        </Row>
        <Row className="auth-row-2">
          <text className="auth-row-2-text">
            Submit your email to reset your password
          </text>
        </Row>
        <Row className="auth-row-3">
          <Form>
            <Form.Item>
              <Input
                rules={[
                  { required: true, message: 'Please input your Email!' },
                ]}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                size="large"
                className="auth-input border-typo-1"
                placeholder="Email"
                prefix={
                  <Fragment>
                    <MailOutlined className="mail-icon" /> &nbsp;&nbsp;
                  </Fragment>
                }
              />
            </Form.Item>
            <Form.Item style={{ width: '100%' }}>
              <Button
                size="large"
                onClick={() => forgotPassReq()}
                disabled={email === null || email === ''}
                style={{
                  width: '100%',
                  backgroundColor:
                    email === null || email === '' ? '#404040' : '#fe8781',
                  borderWidth: 0,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  borderBottomLeftRadius: 10,
                  paddingLeft: '5px',
                  color: '#fff',
                }}
                type="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

export default ForgotPassRequest;
