import { HTTP_METHODS } from 'constants/common';
import axiosInstance from './axiosInstance';

export default {
  getCategories: () => {
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: 'App/Categories',
    });
  },
};
