import React, { Fragment, useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import { Notification } from 'utils/notification';
import auth from 'apis/authentication';
import './styles.css';

const Auth = (props) => {
  const [clinicCode, setClinicCode] = useState(null);
  const [password, setPassword] = useState(null);
  const login = async () => {
    const response = await auth.singIn(password, clinicCode);
    if (response) {
      const fullName =
        (response?.firstname === null ? '' : response?.firstname) +
        (response?.lastname === null ? '' : response?.lastname);
      Notification('success', 'welcome ' + fullName);
      localStorage.setItem('user', JSON.stringify(response));
      return props.history.push('/');
    }   
  };

  return (
    <Row>
      <Col md={14} className="auth-row-1">
        <img className="auth-image" src={require('./../../assets/doc.png')} />
      </Col>

      <Col md={10} className="auth-col-1">
        <Row>
          <text className="auth-col-text">Welcome</text>
        </Row>
        <Row className="auth-row-2">
          <text className="auth-row-2-text">
            Sign in to your account to continue
          </text>
        </Row>
        <Row className="auth-row-3">
          <Form>
            <Form.Item>
              <Input
                rules={[
                  { required: true, message: 'Please input your Email!' },
                ]}
                onChange={(e) => {
                  setClinicCode(e.target.value);
                }}
                size="large"
                className="auth-input border-typo-1"
                placeholder="Email"
                prefix={
                  <Fragment>
                    <MailOutlined className="mail-icon" /> &nbsp;&nbsp;
                  </Fragment>
                }
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                size="large"
                className="auth-input border-typo-1"
                placeholder="Password"
                prefix={
                  <Fragment>
                    <i
                      className="fa fa-shield article-icon"
                      aria-hidden="true"></i>{' '}
                    &nbsp;&nbsp;&nbsp;
                  </Fragment>
                }
              />
            </Form.Item>
            <Form.Item style={{ width: '100%' }}>
              <Button
                size="large"
                onClick={login}
                disabled={
                  clinicCode === null ||
                  password === null ||
                  clinicCode === '' ||
                  password === ''
                }
                style={{
                  width: '100%',
                  backgroundColor:
                    clinicCode === null ||
                      password === null ||
                      clinicCode === '' ||
                      password === ''
                      ? '#404040'
                      : '#fe8781',
                  borderWidth: 0,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  borderBottomLeftRadius: 10,
                  paddingLeft: '5px',
                  color: '#fff',
                }}
                type="submit">
                Sign in
              </Button>
            </Form.Item>
            <Form.Item style={{ margin: '5px' }}>
              <text
                onClick={() => props.history.push('/forgot_pass_req')}
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: 'black',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}>
                Forgot Password ?
              </text>
            </Form.Item>
            <Form.Item style={{ margin: '5px' }}>
              <text
                onClick={() => props.history.push('/privacy-policy')}
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  color: 'black',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}>
                Privacy Policy
              </text>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

export default Auth;
