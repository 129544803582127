import React, { Fragment, useEffect, useState, useCallback } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Tooltip,
} from 'antd';
import { UserOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import MultiSelect from 'react-multi-select-component';

import { Notification } from 'utils/notification';
import SymptomsBar from 'screens/Patient/components/Symptoms';
import ImageBar from 'screens/Patient/components/Image';
import categories from 'apis/categories';
import tags from 'apis/tag';
import clinic from 'apis/clinic';
import symptoms from 'apis/clinic';
import './styles.css';

const { Option } = Select;
const Settings = ({ info, ...props }) => {
  const [symIds, setSymIds] = useState([]);
  const [age, setAge] = useState(null);
  const [name, setName] = useState(null);
  const [phn, setPHN] = useState(null);
  const [pass, setPass] = useState(null);
  const [image, setImage] = useState(null);
  const [birthdate, setBirthDate] = useState(null);
  const [region, setRegion] = useState(null);
  const [states, setStates] = useState([]);
  const [children, setChildren] = useState([]);
  const [gender, setGender] = useState(null);
  const [symps, setSymps] = useState([]);
  const [selected, setSelected] = useState([]);

  const getCategory = async () => {
    const response = await categories.getCategories();
    setStates(response.states);
  };
  const getSymptoms = async () => {
    const response = await symptoms.getSymps();
    setSymps(response);
  };
  const getTags = async () => {
    const tagList = await tags.getTags();
    setChildren(
      tagList.map((item) => {
        return { label: item.name, value: item.id, key: item.id };
      }),
    );
  };
  const initAge = () => {
    if (info.age !== null) setAge(info.age);
    else setAge(0);
    if (info.image !== null) setImage(info.image);
    else setImage(null);
  };

  useEffect(() => {
    getSymptoms();
    getCategory();
    getTags();
    initAge();
  }, []);

  const setSymptonsIds = (id) => {
    let temp = symIds;
    let index = temp.indexOf(id);
    if (index === -1) {
      setSymIds([...temp, id]);
    } else {
      setSymIds([...temp.filter((item) => item !== id)]);
    }
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  const onDateChange = (dateString) => {
    setBirthDate(dateString);
    const today = new Date();
    const birthDate = new Date(dateString);
    let age_now = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    setAge(age_now);
  };
  const menu =
    states && states.length > 0 ? (
      <>
        {states.map((item) => {
          return <Option value={item.id}>{item.name}</Option>;
        })}
      </>
    ) : null;
  const genderMenu = (
    <>
      <Option value={1}>Male</Option>
      <Option value={2}>Female</Option>
      <Option value={3}>Other</Option>
    </>
  );
  return (
    <Row className="first-row-padding">
      <Col span={4} className="setting-col-1">
        <ImageBar
          setImage={(value) => {
            setImage(value);
          }}
          image={image}
        />
      </Col>
      <Col span={14} className="setting-col">
        <Form>
          <Row>
            <Col span={12}>
              <Form.Item>
                <Input
                  size="large"
                  className="todo-input-typo-2 border-typo-2"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder={info.firstname + ' ' + info.lastname}
                  prefix={
                    <Fragment>
                      <UserOutlined className="setting-form-icon" />{' '}
                      &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  disabled
                  size="large"
                  className="todo-input-typo-2 border-typo-2"
                  placeholder={info.email}
                  prefix={
                    <Fragment>
                      <MailOutlined className="setting-form-icon" />{' '}
                      &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Row className="setting-row-2">
                <Col span={18}>
                  <Form.Item>
                    <DatePicker
                      disabledDate={disabledDate}
                      onChange={onDateChange}
                      placeholder={info.birthdate}
                      size="large"
                      className="todo-input-typo-2 border-typo-2"
                    />
                  </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={5} className="setting-col-5">
                  <Form.Item>
                    <Tooltip title="Age">
                      <Input
                        disabled
                        value={age}
                        size="middle"
                        className="age-cont border-typo-2"
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Form.Item>
                  <MultiSelect
                    options={children}
                    value={selected}
                    labelledBy={'Select'}
                    onChange={setSelected}
                    placeholder="Select Tags"
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Select
                  className="select-1"
                  placeholder="Select Region"
                  onChange={(e) =>
                    setRegion(
                      states.find((item) => parseInt(item.id) === parseInt(e)),
                    )
                  }>
                  {menu}
                </Select>
              </Form.Item>
              <Form.Item>
                <Select
                  className="select-1"
                  placeholder="Select Status"
                  onChange={(e) => setGender(parseInt(e))}>
                  {genderMenu}
                </Select>
              </Form.Item>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    setPHN(e.target.value);
                  }}
                  size="large"
                  className="todo-input-typo-2 border-typo-2"
                  placeholder={info.phn === null ? 'PHN' : info.phn}
                  prefix={
                    <Fragment>
                      <PlusOutlined
                        aria-hidden="true"
                        className="fa fa-shield setting-form-icon"
                      />{' '}
                      &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  type="password"
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                  size="large"
                  className="todo-input-typo-2 border-typo-2"
                  placeholder="Password"
                  prefix={
                    <Fragment>
                      <i
                        aria-hidden="true"
                        className="fa fa-shield setting-form-icon"></i>{' '}
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={6} className="setting-col-carousel">
        {symps && symps.length > 0 ? (
          <SymptomsBar
            symps={symps}
            setSymptonsIds={(id) => setSymptonsIds(id)}
          />
        ) : null}
        <Row>
          <Button
            onClick={() => {
              clinic
                .editProfile({
                  patientId: info.id,
                  firstname: name,
                  lastname: null,
                  age,
                  stateId: region ? region.id : null,
                  password: pass,
                  phn,
                  tagIds: selected ? selected : [],
                  symptomIds: symIds,
                  image,
                  birthdate,
                })
                .then(() => {
                  props.load();
                  Notification('success', 'Patient Profile Updated Succefully');
                });
            }}
            size="large"
            className="setting-btn border-typo-2">
            Set
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default Settings;
