import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Tooltip } from 'antd';
import { ToastContainer } from 'react-toastify';

import { Notification } from 'utils/notification';
import categories from 'apis/categories';
import state from 'apis/region';
import './styles.css';

const Region = () => {
  const [states, setStates] = useState([]);
  const [name, setName] = useState(null);
  const [chatLine, setChatLine] = useState(null);
  const [hotLine, setHotLine] = useState(null);

  const LoadRegions = async () => {
    const res = await categories.getCategories();
    setStates(res.states);
  };
  const PostState = async () => {
    await state.addState({ name, chatLine, hotLine });
    LoadRegions();
    Notification('success', 'Region Added Successfully');
    setName(null);
    setChatLine(null);
    setHotLine(null);
  };
  useEffect(() => {
    LoadRegions();
  }, []);

  return (
    <Fragment>
      <ToastContainer
        onClose={() => {
          alert('as');
        }}
        className="toast-container"
      />

      <Row className="row1">
        <Col xs={2} md={2} lg={2} />
        <Col xs={22} md={22} lg={22}>
          <text className="col1-text">Add Regions</text>
        </Col>
      </Row>
      <Row className="row2-main">
        <Col md={2} lg={2} xs={2} />
        <Col md={14} lg={14} xs={14} className="row2-main-col1">
          {states.reverse().map((item) => {
            return (
              <Row className="region-row" key={item.id}>
                <Col lg={6}>
                  <text className="region-row-text">{item.name}</text>
                </Col>
                <Col lg={6}>
                  <text className="hotline">{item.hotLine}</text>
                </Col>
                <Col lg={10}>
                  <text className="hotline">{item.chatLine}</text>
                </Col>
                <Col lg={2}>
                  {JSON.parse(localStorage.getItem('user')).roleId !== 3 && (
                    <div
                      onClick={() => {
                        state.removeState(item.id).then((res) => {
                          LoadRegions();
                        });
                      }}>
                      <Tooltip title="Delete Region">
                        <text
                          aria-hidden="true"
                          className="trash-icon fa fa-trash-o"
                        />
                      </Tooltip>
                    </div>
                  )}
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col md={2} lg={2} xs={2} />
        <Col md={6} lg={6} xs={6}>
          <Form>
            <Form.Item>
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                size="large"
                className="border-typo input-typo"
                placeholder="Name"
              />
            </Form.Item>
            <Form.Item>
              <Input
                value={chatLine}
                onChange={(e) => {
                  setChatLine(e.target.value);
                }}
                size="large"
                className="border-typo input-typo"
                placeholder="Chat Line"
              />
            </Form.Item>
            <Form.Item>
              <Input
                value={hotLine}
                onChange={(e) => {
                  setHotLine(e.target.value);
                }}
                size="large"
                className="border-typo input-typo"
                placeholder="Hot Line"
              />
            </Form.Item>
            <Button
              disabled={hotLine === null || chatLine === null || name === null}
              size="large"
              onClick={PostState}
              type="submit"
              className={
                hotLine !== null || chatLine !== null || name !== null
                  ? 'btn-typo border-typo'
                  : 'btn-typo-disabled  border-typo'
              }>
              Add
            </Button>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Region;
