import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Popover, Tooltip, Card } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import ReactSearchBox from 'react-search-box';

import CreateClinic from 'screens/Clinics/components/CreateClinic';
import clinic from 'apis/clinic';
import './styles.css';

const { Meta } = Card;
const ClinicProfile = () => {
  const [createClinic, setCreateClinic] = useState(false);
  const [clinics, setClinics] = useState([]);

  const LoadClinics = async () => {
    const res = await clinic.getClinics();
    setClinics(res);
  };

  useEffect(() => {
    LoadClinics();
  }, [createClinic]);
  return (
    <Fragment>
      <Row className="row1">
        {!createClinic ? (
          <>
            <Col md={8}>
              <text className="col1-text">Clinic Profiles</text>
            </Col>
            <Col md={2} className="col2">
              <SearchOutlined className="col2-search" />
            </Col>
            <Col md={12} style={{ marginLeft: '-75px' }}>
              <ReactSearchBox
                placeholder="Search"
                inputBoxBorderColor={'#fff'}
                onChange={(text) => {
                  if (text)
                    setClinics(
                      clinics.filter((item) => {
                        item.name.includes(text);
                      }),
                    );
                  else LoadClinics();
                }}
                callback={(record) => alert(record)}
              />
            </Col>
            <Col md={2} className="col4">
              <Tooltip title="Create Clinic">
                <PlusOutlined
                  className="col4-plus"
                  onClick={() => {
                    setCreateClinic(!createClinic);
                  }}
                />
              </Tooltip>
            </Col>
          </>
        ) : (
          <>
            <Col md={4} lg={4} xs={4}>
              <Tooltip title="Back To Clinic List">
                <i
                  onClick={() => {
                    setCreateClinic(!createClinic);
                  }}
                  className="fa fa-arrow-left col5-tooltip"
                  aria-hidden="true"
                />
              </Tooltip>
            </Col>
            <Col md={20} lg={20} xs={20} className="col6">
              <text className="col6-text">Create Clinic</text>
            </Col>
          </>
        )}
      </Row>
      {createClinic ? (
        <CreateClinic />
      ) : (
        <Row className="row2">
          {clinics.map((i) => (
            <Popover
              placement="bottomLeft"
              content={
                <Fragment>
                  <Row className="row2-2">
                    <Col md={8} lg={8} xs={8}>
                      <text className="text-title">Clinic :</text>
                    </Col>
                    <Col md={16} lg={16} xs={16}>
                      <text className="text-number">{i.name}</text>
                    </Col>
                  </Row>
                  <Row className="row2-2">
                    <Col md={20} lg={20} xs={20}>
                      <text className="text-title">Patients</text>
                    </Col>
                    <Col md={4} lg={4} xs={4}>
                      <text className="text-number">{i.totalPatient}</text>
                    </Col>
                  </Row>
                  <Row style={{ width: '150px' }}>
                    <Col md={20} lg={20} xs={20}>
                      <text className="text-title">Parents</text>
                    </Col>
                    <Col md={4} lg={4} xs={4}>
                      <text className="text-number">{i.totalParent}</text>
                    </Col>
                  </Row>
                  <Row style={{ width: '150px' }}>
                    <Col md={20} lg={20} xs={20}>
                      <text className="text-title">Offical Patent</text>
                    </Col>
                    <Col md={4} lg={4} xs={4}>
                      <text className="text-number">{i.officialPatent}</text>
                    </Col>
                  </Row>
                </Fragment>
              }
              background="white"
              radius="10"
              border="#404040"
              fontSize="10px">
              <Col span={4} className="clinic-col-main">
                <Card
                  className="clinic-card"
                  cover={
                    <img
                      alt="example"
                      src={
                        i.image !== null
                          ? i.image
                          : require('assets/clinic.jpg')
                      }
                      className="card-image"
                    />
                  }></Card>
              </Col>
            </Popover>
          ))}
        </Row>
      )}
    </Fragment>
  );
};

export default ClinicProfile;
