import { HTTP_METHODS } from 'constants/common';
import axiosInstance from './axiosInstance';
import { auth } from 'utils/auth';

export default {
  getArticles: () => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/Articles',
      headers: ApiHeader,
    });
  },
  addArticle: ({
    typeId,
    categoryId,
    title,
    text,
    image,
    selectedTags,
    Links,
  }) => {
    const tags = selectedTags.map((item) => {
      return {
        id: item.value,
        name: item.label,
        parentId: item.parentId,
      };
    });
    const data = {
      typeId,
      categoryId,
      title,
      text,
      image,
      tags,
      Links,
    };
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: Role + '/Article',
      data,
      headers: ApiHeader,
    });
  },
  deleteArticle: (id) => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.DELETE,
      url: Role + '/DeleteArticle?articleId=' + id,
      headers: ApiHeader,
    });
  },
  editArticle: ({
    articleId,
    typeId,
    categoryId,
    title,
    text,
    image,
    selectedTags,
  }) => {
    const tags = selectedTags.map((item) => {
      return {
        id: item.value,
        name: item.label,
        parentId: item.parentId,
      };
    });
    const data = {
      articleId,
      typeId,
      categoryId,
      title,
      text,
      image,
      tags,
    };
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.PUT,
      url: Role + '/EditArticle',
      data,
      headers: ApiHeader,
    });
  },
};
