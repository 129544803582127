import React, { Fragment } from 'react';
import ReactSearchBox from 'react-search-box';
import { Row, Col, Tooltip, Alert } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import './styles.css';

const Header = ({ temp, setArticles, mood, setMood }) => {
  return (
    <Fragment>
      {mood == 0 ? (
        <Row className="col-1-row-2">
          <Col md={6}>
            <text className="header-text">Articles List</text>
          </Col>
          <Col md={14}>
            <Row>
              <SearchOutlined className="search-icon" />
              <ReactSearchBox
                placeholder="Search"
                inputBoxBorderColor={'#fff'}
                onChange={(text) => {
                  setArticles(
                    temp.filter(
                      (item) =>
                        item.title.includes(text) || item.text.includes(text),
                    ),
                  );
                }}
              />
            </Row>
          </Col>
          <Col md={2}>
            <Tooltip title="Add Article">
              <PlusOutlined
                onClick={() => {
                  setMood(1);
                }}
                className="article-icon search-icon"
              />
            </Tooltip>
          </Col>
        </Row>
      ) : (
        <Row className="col-1-row-1">
          <Col md={2}>
            <Tooltip title="Back To Article List">
              <i
                onClick={() => {
                  setMood(0);
                }}
                className="fa fa-arrow-left article-icon"
                aria-hidden="true"
              />
            </Tooltip>
          </Col>
          <Col md={8} className="col-1-col-2">
            <text className="header-text">
              {mood === 1 ? 'Create Article' : 'Article Detail'}
            </text>
          </Col>
          <Col md={14}>
            <Alert
              message="Upload Image"
              description="Your article image is final Image thas has been uploaded "
              type="info"
              showIcon
              closable="true"
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default Header;
