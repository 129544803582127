import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';

export default {
  sendChatFile: ({ file, toRole, toId }) => {
    const data = new FormData();

    data.append('type', toRole);
    data.append('toId', toId);
    data.append('file', file, file.name);
    const { Token } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: '/file/clinicUpload',
      baseURL: 'https://brze.info:7273',
      data,
      headers: { token: Token, 'Cache-Control': 'no-cache' },
    });
  },
  sendGloblaChatFile: ({ file, toRole, groupId }) => {
    const data = new FormData();
    data.append('type', toRole);
    data.append('groupId', groupId);
    data.append('file', file, file.name);
    const { Token } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: '/file/clinicUpload',
      baseURL: 'https://brze.info:7273',
      data,
      headers: { token: Token, 'Cache-Control': 'no-cache' },
    });
  },
  addGroup: ({ tagIds, title, audience }) => {
    const temp = tagIds.map((item) => {
      return item.value;
    });
    const data = {
      name: title,
      tags: temp,
      audience,
    };
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: Role + '/Group',
      data,
      headers: ApiHeader,
    });
  },
};
