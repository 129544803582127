import React from 'react';
import { Row, Col, Popconfirm, Tooltip } from 'antd';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { LogoutOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { Notification } from 'utils/notification';
import { getUser } from 'utils/auth';
import auth from 'apis/authentication';
import './styles.css';

const ClinicLinks = [
  { name: 'Overview', path: '/home' },
  { name: 'Profiles', path: '/profiles' },
  { name: 'Articles', path: '/articles' },
  { name: 'Messenger', path: '/messenger' },
  { name: 'Clinicians', path: '/staff' },
  { name: 'Region', path: '/Region' },
];
const DoctorLinks = [
  { name: 'Overview', path: '/home' },
  { name: 'Profiles', path: '/profiles' },
  { name: 'Articles', path: '/articles' },
  { name: 'Messenger', path: '/messenger' },
];
const AdminLinks = [
  { name: 'Overview', path: '/home' },
  { name: 'Clinics', path: '/Clinics' },
  { name: 'Region', path: '/Region' },
];
const Header = () => {
  const history = useHistory();
  const getActiveRoute = () => {
    const currentRoute = history.location.pathname;
    return currentRoute;
  };
  const { roleId, firstname, lastname, clinicCode, clinicName } = getUser();
  const getLinks = () => {
    if (roleId === 4) return AdminLinks;
    else if (roleId === 3) return ClinicLinks;
    return DoctorLinks;
  };

  const logout = async () => {
    await auth.signOut();
    Notification('info', 'See you next time !');
    localStorage.setItem('user', JSON.stringify({}));
    history.push('/login');
  };

  return (
    <Row justify="center" align="middle" className="main-row">
      <Col span={2}>
        <Tooltip placement="bottom" title="Breeze">
          <img src="./../../img/Doctors.png" alt="Smile" className="logo-img" />
        </Tooltip>
      </Col>
      <Col span={13}>
        <Row justify="center" align="middle">
          {getLinks().map((item) => {
            return (
              <Col span={3} className="header-col-1">
                <Row justify="center" align="middle">
                  <Link
                    to={item.path}
                    className={
                      getActiveRoute() === item.path
                        ? 'header-active-link'
                        : 'header-deactive-link'
                    }>
                    {item.name}
                  </Link>
                </Row>
                <Row className="header-row-2" justify="center" align="middle">
                  {getActiveRoute() === item.path && (
                    <div className="underline-link" />
                  )}
                </Row>
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col span={4} />
      <Col span={1}>
        <Popconfirm
          onConfirm={() => {
            logout();
          }}
          okText="Yes"
          title="Are you sure to logout？"
          icon={<LogoutOutlined className="logout-color" />}>
          {' '}
          <i className="fa fa-power-off i-icon" aria-hidden="true" />
        </Popconfirm>
      </Col>
      <Col span={4}>
        <Row className="row-justify">
          <text className="header-name">
            {firstname}&nbsp;
            {lastname}
          </text>
        </Row>
        <Row className="clinic-name">
          <text>{clinicName}</text>
        </Row>
        <Row className="row-justify">
          {clinicCode !== null ? (
            <>
              <Tooltip placement="bottom" title="Clinic Code">
                <InfoCircleOutlined className="info-icon" />
                &nbsp;
                <text className="info-text">{clinicCode}</text>
              </Tooltip>
            </>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default withRouter(Header);
