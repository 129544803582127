import axios from 'axios';
import { Notification } from 'utils/notification';
import { BASE_URL } from 'constants/common';

const instance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Cache-Control': 'no-cache' },
});

instance.interceptors.response.use(
  (response) => {
    if (response.data.isSuccess) {
      return response.data.data;
    } else {
      Notification('error', response.data.message);
    }
  },
  (error) => {
    Notification(
      'error',
      error.response.data.Message == null
        ? 'Oops Something Wrong has happend'
        : error.response.data.Message,
    );
    return Promise.reject({ data: { isSuccess: false } });
  },
);

export default instance;
