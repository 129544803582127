import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';

export default {
  getClinics: () => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/Clinics',
      headers: ApiHeader,
    });
  },
  addClinic: ({
    name,
    stateId,
    doctorName,
    email,
    password,
    image,
    phoneNumber,
  }) => {
    const data = {
      name,
      stateId,
      doctorName,
      email,
      password,
      image,
      phoneNumber,
    };
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: Role + '/Clinic',
      data,
      headers: ApiHeader,
    });
  },
  getSymps: () => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/Symptoms',
      headers: ApiHeader,
    });
  },
  editProfile: ({
    patientId,
    firstname,
    lastname,
    age,
    stateId,
    password,
    phn,
    tagIds,
    symptomIds,
    image,
    birthdate,
  }) => {
    let temp = [];
    if (tagIds && tagIds.length > 0) {
      temp = tagIds.map((item) => {
        return item.value;
      });
    }
    const data = {
      patientId,
      firstname,
      lastname,
      age,
      birthdate,
      stateId,
      password,
      isActive: true,
      image,
      phn,
      tagIds: temp,
      symptomIds,
    };
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: Role + '/PatientProfile',
      data,
      headers: ApiHeader,
    });
  },
  getStaff: () => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/Staffs',
      headers: ApiHeader,
    });
  },
  getDoctors: () => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/Doctors',
      headers: ApiHeader,
    });
  },
  setDoctor: (doctorId, patientId) => {
    const data = {
      patientId,
      doctorId,
    };
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.PUT,
      url: Role + '/SetDoctor',
      data,
      headers: ApiHeader,
    });
  },
  setPatientInterval: (interval, userId) => {
    const data = {
      userId,
      interval,
    };
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: Role + '/Interval',
      data,
      headers: ApiHeader,
    });
  },
  sendNotification: (toId) => {
    const data = {
      toId,
    };
    const { Token } = auth();
    return axiosInstance({
      baseURL: 'https://brze.info:7273',
      method: HTTP_METHODS.POST,
      data,
      url: 'file/clinicSendNotification',
      headers: {
        token: Token,
        toId: toId,
        'Cache-Control': 'no-cache',
      },
    });
  },
  getLastRecord: (id) => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/LastRecord?userId=' + id,
      headers: ApiHeader,
    });
  },
};
