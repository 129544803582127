import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';

export default {
  addState: (data) => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: Role + '/State',
      data,
      headers: ApiHeader,
    });
  },
  removeState: (id) => {
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.DELETE,
      url: 'App/DeleteRegion?id=' + id,
      headers: ApiHeader,
    });
  },
};
