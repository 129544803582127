import React, { useState, useEffect } from 'react';
import { Row, Col, Tooltip } from 'antd';

import './styles.css';

const ShowCarousel = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      props.data.map((item) => {
        return {
          ...item,
          selection: false,
        };
      }),
    );
  }, []);

  return (
    <Row justify="center" align="middle">
      {data.map((item) => {
        return (
          <>
            <Col
              span={2}
              className={
                item.selection
                  ? 'carousel-selected-item-div border-div-typo-2'
                  : 'carousel-item-div border-div-typo-2'
              }>
              <Tooltip title={item.name} placement="bottomLeft">
                <img
                  src={item.imageLink}
                  alt="Card image"
                  className="carousel-img"
                />
              </Tooltip>
            </Col>
          </>
        );
      })}
    </Row>
  );
};

export default ShowCarousel;
