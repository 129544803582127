import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import { Row, Col, Tooltip, Button, Popconfirm, Table } from 'antd';
import ReactSearchBox from 'react-search-box';
import {
  SearchOutlined,
  DisconnectOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import CreateStaff from 'screens/Staff/components/CreateStaff';
import clinic from 'apis/clinic';
import staff from 'apis/staff';
import './styles.css';

const { Column } = Table;
const sampleImage =
  'https://www.duffyduffylaw.com/wp-content/uploads/2018/05/user-placeholder-200x250.jpg';
const Staff = (props) => {
  const [createStaff, setCreateStaff] = useState(false);
  const [users, setUsers] = useState([]);

  const changeCreateStaff = () => {
    setCreateStaff(false);
  };
  const getUsers = () => {
    clinic.getStaff().then((response) => {
      setUsers(response);
    });
  };
  useEffect(() => {
    clinic.getStaff().then((response) => {
      setUsers(response);
    });
  }, []);
  const getDay = (date) => {
    if (date !== null) {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return startDate.format('dddd').substring(0, 3);
    } else return <text className="date-text">No Data</text>;
  };
  const getTime = (date) => {
    if (date !== null) {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return `${startDate.format('D')} ${startDate.format('MMMM')}`;
    } else return null;
  };

  return (
    <Fragment>
      <Row className="row-1">
        {!createStaff ? (
          <>
            <Col md={8}>
              <text className="row-1-col-1-text">Clinicians</text>
            </Col>
            <Col md={2} className="row-1-col-2-header">
              <SearchOutlined className="col-2-search-icon" />
            </Col>
            <Col md={12} className="row-1-col-3">
              <ReactSearchBox
                placeholder="Search"
                inputBoxBorderColor={'#fff'}
                onChange={(text) => {
                  if (text)
                    setUsers(
                      users.filter((item) => item.firstname.includes(text)),
                    );
                  else getUsers();
                }}
              />
            </Col>
            <Col md={2} className="row-1-col-4">
              <Tooltip title="Create Clinician">
                <PlusOutlined
                  className="plus-icon"
                  onClick={() => {
                    setCreateStaff(!createStaff);
                  }}
                />
              </Tooltip>
            </Col>
          </>
        ) : (
          <>
            <Col md={2} lg={2} xs={2}>
              <Tooltip title="Back To Clinician List">
                <i
                  onClick={() => {
                    setCreateStaff(!createStaff);
                  }}
                  className="fa fa-arrow-left right-icon"
                  aria-hidden="true"
                />
              </Tooltip>
            </Col>
            <Col md={22} lg={22} xs={22} className="row-1-col-5">
              <text className="header-text">Create Clinician</text>
            </Col>
          </>
        )}
      </Row>
      {createStaff ? (
        <CreateStaff
          get={() => {
            getUsers();
          }}
          change={() => {
            changeCreateStaff();
          }}
        />
      ) : (
        <Row className="show-staff-row-1">
          <Table
            size="small"
            dataSource={users}
            scroll={{ y: 600 }}
            pagination={{ pageSize: 6, position: 'bottomLeft' }}
            onSelect={(id) => {
              props.history.push({
                pathname: '/info',
                state: { id: id },
              });
            }}>
            <Column
              title="#"
              dataIndex="image"
              key="image"
              render={(image) => (
                <>
                  {
                    <img
                      src={image != null ? image : sampleImage}
                      className="table-img"
                    />
                  }
                </>
              )}
            />
            <Column title="First Name" dataIndex="firstname" key="firstname" />
            <Column title="Last Name" dataIndex="lastname" key="lastname" />
            <Column
              title="Last Login Datetime"
              dataIndex="lastLoginDatetime"
              key="lastLoginDatetime"
              render={(lastLoginDatetime) => (
                <>
                  {getDay(lastLoginDatetime)}
                  <br />
                  {getTime(lastLoginDatetime)}
                </>
              )}
            />
            <Column title="Email" dataIndex="email" key="email" />
            <Column title="Phone" dataIndex="phoneNumber" key="PhoneNumber" />
            <Column
              title="Gender"
              dataIndex="gender"
              key="gender"
              render={(gender) => (
                <>
                  {gender === 0 ? (
                    <text className="gender-text">Male</text>
                  ) : (
                    <text className="gender-text">FeMale</text>
                  )}
                </>
              )}
            />
            <Column
              title=""
              dataIndex="isActive"
              key="isActive"
              render={(isActive, record) => (
                <>
                  {isActive === true ? (
                    <Popconfirm
                      onConfirm={() => {
                        staff.activateStaff(record.id).then(() => {
                          clinic.getStaff().then((response) => {
                            setUsers(response);
                          });
                        });
                      }}
                      okText="Yes"
                      title="Are you sure to deactive this clinician?"
                      icon={<DisconnectOutlined className="discon-icon" />}>
                      <Tooltip title="Deactivate">
                        <Button className="active-table-btn border-typo-1">
                          Deactivate
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      onConfirm={() => {
                        staff.activateStaff(record.id).then(() => {
                          clinic.getStaff().then((response) => {
                            setUsers(response);
                          });
                        });
                      }}
                      okText="Yes"
                      title="Are you sure to Active this clinician?"
                      icon={<DisconnectOutlined className="discon-icon" />}>
                      <Tooltip title="Activate">
                        <Button className="deactive-table-btn border-typo-1">
                          Activate
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  )}
                </>
              )}
            />
          </Table>
        </Row>
      )}
    </Fragment>
  );
};

export default Staff;
