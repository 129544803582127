import React, { useRef } from 'react';
import { Tooltip } from 'antd';
import auth from 'apis/authentication';
import './styles.css';

const defaultImage =
  'https://www.duffyduffylaw.com/wp-content/uploads/2018/05/user-placeholder-200x250.jpg';
const ImageBar = ({ setImage, image }) => {
  const inputFile = useRef(null);
  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    auth.upload(file).then((res) => {
      setImage(res);
    });
  };
  return (
    <>
      <input
        type={'file'}
        ref={inputFile}
        className="input-pick"
        onChange={onChangeFile}
      />
      <Tooltip title="Click To Upload Or Change Image" placement="bottom">
        <img
          onClick={() => {
            inputFile.current.click();
          }}
          src={image != null ? image : defaultImage}
          size="large"
          className="setting-img border-typo-2"
        />
      </Tooltip>
    </>
  );
};

export default ImageBar;
