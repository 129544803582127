import React, { useState } from 'react';
import { Row, Col } from 'antd';

import ShowCarousel from 'components/ShowCarousel';
import Month from 'screens/Patient/components/MonthReport';
import './styles.css';

const QA = ({ info: { symptoms } }) => {
  const [better, setBetter] = useState(false);
  const [early, setEarly] = useState(false);
  const [stressor, setStressor] = useState(false);

  return (
    <Row className="first-row-padding">
      <Col span={12}>
        <Row className="qa-row-2">
          <Col span={20}>
            <text className={early ? 'active-qa-header' : 'deactive-qa-header'}>
              Early Warning Signs
            </text>
          </Col>
          <Col span={4}>
            <i
              onClick={() => setEarly(!early)}
              class={`fa fa-arrow-${early ? 'up' : 'down'} i-icon`}
              aria-hidden="true"></i>
          </Col>
        </Row>
        <Row className="qa-row-margin">
          {symptoms && symptoms.filter((a) => a.typeId === 3).length > 0 ? (
            <div className={early ? 'active-row-1' : 'deactive-row-1'}>
              <ShowCarousel data={symptoms.filter((a) => a.typeId === 3)} />
            </div>
          ) : (
            <div className={early ? 'active-row-1' : 'deactive-row-1'}>
              <text className="nothing-text">Nothing To Show</text>
            </div>
          )}
        </Row>
        <Row className="qa-row-2">
          <Col span={20}>
            <text
              className={stressor ? 'active-qa-header' : 'deactive-qa-header'}>
              Stressors
            </text>
          </Col>
          <Col span={4}>
            <i
              onClick={() => setStressor(!stressor)}
              class={`fa fa-arrow-${stressor ? 'up' : 'down'} i-icon`}
              aria-hidden="true"></i>
          </Col>
        </Row>
        <Row className="qa-row-margin">
          {symptoms && symptoms.filter((a) => a.typeId === 2).length > 0 ? (
            <div className={stressor ? 'active-row-1' : 'deactive-row-1'}>
              <ShowCarousel data={symptoms.filter((a) => a.typeId === 2)} />
            </div>
          ) : (
            <div className={stressor ? 'active-row-1' : 'deactive-row-1'}>
              <text className="nothing-text">Nothing To Show</text>
            </div>
          )}{' '}
        </Row>
        <Row className="qa-row-2">
          <Col span={20}>
            <text
              className={better ? 'active-qa-header' : 'deactive-qa-header'}>
              Feel Better
            </text>
          </Col>
          <Col span={4}>
            <i
              onClick={() => setBetter(!better)}
              class={`fa fa-arrow-${better ? 'up' : 'down'} i-icon`}
              aria-hidden="true"></i>
          </Col>
        </Row>
        <Row className="qa-row-margin">
          {symptoms && symptoms.filter((a) => a.typeId === 1).length > 0 ? (
            <div className={better ? 'active-row-1' : 'deactive-row-1'}>
              <ShowCarousel data={symptoms.filter((a) => a.typeId === 1)} />
            </div>
          ) : (
            <div className={better ? 'active-row-1' : 'deactive-row-1'}>
              <text className="nothing-text">Nothing To Show</text>
            </div>
          )}
        </Row>
      </Col>
      <Col span={12}>
        <Row className="qa-row-2">
          <Col span={2}>
            <div className="self-last-div"></div>
          </Col>
          <Col span={22}>
            <text className="topic-text">Self Harm</text>
          </Col>
        </Row>
        <Row>
          <Col span={2}>
            <div className="self-last-div-bottom"></div>
          </Col>
          <Col span={22}>
            <text className="topic-text">Suicidal</text>
          </Col>
        </Row>
        <Row>
          <Month />
        </Row>
      </Col>
    </Row>
  );
};

export default QA;
