import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';

export default {
  getPatients: () => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/Patients',
      headers: ApiHeader,
    });
  },
  getPatientDetail: (id) => {
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: Role + '/UserDetail?userId=' + id,
      headers: ApiHeader,
    });
  },
  getPatientPmBadges: (id) => {
    return axiosInstance({
      baseURL: 'https://brze.info:7273',
      method: HTTP_METHODS.GET,
      url: 'file/clinic/' + id,
    });
  },
  addPatient: ({
    firstname,
    birthdate,
    gender,
    phoneNumber,
    email,
    password,
    image,
    symptomIds,
    phn,
    age,
    region,
    selected,
  }) => {
    const temp = selected.map((item) => {
      return item.value;
    });
    const data = {
      firstname,
      lastname: '',
      birthdate: birthdate,
      age,
      gender: gender === 'Others' ? 3 : gender === 'Male' ? 1 : 2,
      phoneNumber,
      email,
      password,
      image,
      stateId: region,
      phn,
      tagIds: temp,
      symptomIds,
    };
    const { Role, ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: Role + '/CreateProfile',
      data,
      headers: ApiHeader,
    });
  },
};
