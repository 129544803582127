import React, { useState } from 'react';
import { Row, Col } from 'antd';
import CustomCarousel from 'components/CustomCarousel';
import './styles.css';

const SymptonsBar = ({ setSymptonsIds, symps }) => {
  const [stressors, setStressors] = useState(false);
  const [early, setEarly] = useState(false);
  const [feelBetter, setFeelBetter] = useState(false);
  const setStressorTrue = () => {
    setStressors(true);
    setFeelBetter(false);
    setEarly(false);
  };
  const setFeelBetterTrue = () => {
    setStressors(false);
    setFeelBetter(true);
    setEarly(false);
  };
  const setEarlyTrue = () => {
    setStressors(false);
    setFeelBetter(false);
    setEarly(true);
  };
  return (
    <>
      <Row className="prof-last-row-1">
        <Col span={20}>
          <text className={stressors ? 'active-text' : 'deactive-text'}>
            Stressors
          </text>
        </Col>
        <Col span={4}>
          <i
            onClick={
              stressors ? () => setStressors(false) : () => setStressorTrue()
            }
            className={`fa fa-arrow-${stressors ? 'up' : 'down'} prof-${
              stressors ? 'up' : 'down'
            }-icon`}
            aria-hidden="true"
          />
        </Col>
      </Row>

      <Row className={stressors ? 'active-row' : 'deactive-row'}>
        {symps.length > 0 ? (
          <CustomCarousel
            setItems={(array) => {
              setSymptonsIds(array);
            }}
            editable={true}
            data={symps.filter((a) => a.typeId === 2)}
          />
        ) : null}
      </Row>

      <Row className="prof-last-row-1">
        <Col span={20}>
          <text className={early ? 'active-text' : 'deactive-text'}>
            Early Warning Signs
          </text>
        </Col>
        <Col span={4}>
          <i
            onClick={early ? () => setEarly(false) : () => setEarlyTrue()}
            className={`fa fa-arrow-${early ? 'up' : 'down'} prof-${
              early ? 'up' : 'down'
            }-icon`}
            aria-hidden="true"
          />
        </Col>
      </Row>
      <Row className={early ? 'active-row' : 'deactive-row'}>
        {symps.length > 0 ? (
          <CustomCarousel
            setItems={(array) => {
              setSymptonsIds(array);
            }}
            editable={true}
            data={symps.filter((a) => a.typeId === 3)}
          />
        ) : null}
      </Row>
      <Row className="prof-last-row-1">
        <Col span={20}>
          <text className={feelBetter ? 'active-text' : 'deactive-text'}>
            Feel Better
          </text>
        </Col>
        <Col span={4}>
          <i
            onClick={
              feelBetter
                ? () => setFeelBetter(false)
                : () => setFeelBetterTrue()
            }
            className={`fa fa-arrow-${feelBetter ? 'up' : 'down'} prof-${
              feelBetter ? 'up' : 'down'
            }-icon`}
            aria-hidden="true"
          />
        </Col>
      </Row>
      <Row className={feelBetter ? 'active-row' : 'deactive-row'}>
        {symps.length > 0 ? (
          <CustomCarousel
            setItems={(array) => {
              setSymptonsIds(array);
            }}
            editable={true}
            data={symps.filter((a) => a.typeId === 1)}
          />
        ) : null}
      </Row>
    </>
  );
};

export default SymptonsBar;
