import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';

export default {
  addStaff: ({
    name,
    lastname,
    dateTime,
    gender,
    phoneNumber,
    email,
    password,
    image,
    age,
  }) => {
    const data = {
      firstname: name,
      lastname,
      birthdate: dateTime,
      age,
      gender: gender === 'Others' ? 3 : gender === 'Male' ? 1 : 2,
      phoneNumber,
      email,
      password,
      image,
      stateId: null,
      phn: null,
      tagIds: [],
      symptomIds: [],
    };
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.POST,
      url: 'Clinic/SignUpStaff',
      data,
      headers: ApiHeader,
    });
  },
  activateStaff: (id) => {
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.PUT,
      url: 'Clinic/ActivateAccount?userId=' + id,
      headers: ApiHeader,
    });
  },
};
