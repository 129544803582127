import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Input, Drawer } from 'antd';
import { PaperClipOutlined, SmileOutlined } from '@ant-design/icons';
import { ChatFeed, ChatBubble } from 'react-bell-chat';
import Picker from 'emoji-picker-react';
import io from 'socket.io-client';

import chat from 'apis/sendChatFile';
import { groupAuthors } from 'constants/mock';
import DrawerHeader from 'screens/Messanger/components/DrawerHeader';
import './styles.css';

const socket = io('https://brze.info:9001');
const ChatDrawer = ({
  chosenChat,
  messages,
  setMessages,
  visible,
  setVisible,
}) => {
  const [emojyPanel, setEmojyPanel] = useState(false);
  const [offset, setOffset] = useState(0);
  const [load, setLoad] = useState(false);
  const [input, setInput] = useState('');
  const [toRole, setToRole] = useState('patient');

  const inputFile = useRef(null);

  const sendMessage = () => {
    const Token = JSON.parse(localStorage.getItem('user')).token;
    const payload = {
      token: Token,
      groupId: chosenChat.id,
      message: input.length > 10 ? input : '        ' + input,
      type: toRole,
    };
    socket.emit('onClinicPm', JSON.stringify(payload));
    setInput('');
  };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];

    chat.sendGloblaChatFile(file, toRole, chosenChat.id).then((response) => {
      let temp = messages;
      temp.push({
        id: response._id,
        authorId: response.toRole === 'clinic' ? 1 : 2,
        message: response.message,
        createdOn: new Date(response.createdAt),
        isSend: true,
      });

      setMessages(temp);
    });
  };

  useEffect(() => {
    socket.on('answer', (data) => {
      switch (data.type) {
        case 'clinicOnGlobalLine':
          let temp = data.data;
          let serverMessages = [];

          temp.forEach((item) => {
            if (item.messageType === 'file') {
              if (
                serverMessages.filter((a) => a.fileHash === item.fileHash)
                  .length === 0
              ) {
                serverMessages.push(item);
              }
            } else {
              if (
                serverMessages.filter((a) => a.message === item.message)
                  .length === 0
              ) {
                serverMessages.push(item);
              }
            }
          });

          let normlizedMessages = [];
          serverMessages.forEach((message) => {
            normlizedMessages.push({
              id: message._id,
              authorId: message.toRole === 'clinic' ? 1 : 2,
              message: message.message,
              createdOn: new Date(message.createdAt),
              isSend: true,
              fileHash: message.fileHash,
              toRole: message.toRole,
              messageType: message.messageType,
              seenStatus: message.seenStatus,
              deliverStatus: message.deliverStatus,
            });
          });

          setMessages([...normlizedMessages.reverse(), ...messages]);

          break;
        case 'newMessage':
          let Messages = messages;
          if (data.data.messageType === 'file') {
            if (
              Messages.filter((a) => a.fileHash === data.data.fileHash)
                .length === 0
            ) {
              Messages.push({
                id: data.data._id,
                authorId: data.data.toRole === 'clinic' ? 1 : 2,
                message: data.data.message,
                createdOn: new Date(data.data.createdAt),
                isSend: true,
                fileHash: data.data.fileHash,
                toRole: data.data.toRole,
                messageType: data.data.messageType,
                seenStatus: data.data.seenStatus,
                deliverStatus: data.data.deliverStatus,
              });
              setMessages(Messages);
            }
          } else {
            if (
              Messages.filter((a) => a.message === data.data.message).length ===
              0
            ) {
              Messages.push({
                id: data.data._id,
                authorId: data.data.toRole === 'clinic' ? 1 : 2,
                message: data.data.message,
                createdOn: new Date(data.data.createdAt),
                isSend: true,
                fileHash: data.data.fileHash,
                toRole: data.data.toRole,
                messageType: data.data.messageType,
                seenStatus: data.data.seenStatus,
                deliverStatus: data.data.deliverStatus,
              });
              setMessages(Messages);
            }
          }
          break;
      }
    });
  }, [messages]);

  return (
    <Drawer
      title={<DrawerHeader />}
      placement="right"
      closable={false}
      onClose={setVisible(false)}
      visible={visible}
      width={400}
      headerStyle={{
        borderBottomRightRadius: 50,
        height: '20%',
        boxShadow: '2px 2px 4px #f4f6f8',
      }}
      drawerStyle={{ borderBottomLeftRadius: '50px' }}>
      <ChatFeed
        messages={messages}
        className={'chat'}
        authors={groupAuthors}
        yourAuthorId={2}
        showLoadingMessages={load}
        hasOldMessages={true}
        loadOldMessagesThreshold={10}
        showDateRow={true}
        onLoadOldMessages={() => {
          setLoad(true);
          const Token = JSON.parse(localStorage.getItem('user')).token;
          socket.emit('clinicOnLine', {
            token: Token,
            toRole: toRole,
            offset: offset,
            userId: chosenChat.id,
          });
          setOffset(offset + 10);
          setLoad(false);
        }}
        customChatBubble={(message) => {
          if (message.message.messageType === 'file') {
            const Token = JSON.parse(localStorage.getItem('user')).token;

            return (
              <Row>
                <Col md={4}>
                  <text> </text>
                  {message.message.toRole === 'clinic' && (
                    <a
                      href={
                        'https://brze.info:7273/file/download/' +
                        message.message.fileHash +
                        '?token=' +
                        Token
                      }
                      download>
                      <div className="drawer-body-div">
                        <text style={{ color: '#fff' }}>
                          {message.message.fileHash.split('.')[1]} File
                        </text>
                      </div>
                    </a>
                  )}
                </Col>
                <Col md={4} />
                <Col md={4}>
                  <text> </text>
                  {message.message.toRole !== 'clinic' && (
                    <a
                      href={
                        'https://brze.info:7273/file/download/' +
                        message.message.fileHash +
                        '?token=' +
                        Token
                      }
                      download>
                      <div className="drawer-body-div">
                        <text style={{ color: '#fff' }}>
                          {message.message.fileHash.split('.')[1]} File
                        </text>
                      </div>
                    </a>
                  )}
                </Col>
              </Row>
            );
          } else {
            return <ChatBubble message={message.message} yourAuthorId={2} />;
          }
        }}
        showRecipientAvatar={false}
        showRecipientLastSeenMessage={false}
      />
      {emojyPanel && (
        <Picker
          onEmojiClick={(_, emojiObject) => {
            setInput(input + emojiObject.emoji);
          }}
        />
      )}
      <div className="input-container">
        <Input
          value={input}
          onPressEnter={() => {
            sendMessage();
          }}
          onChange={(a) => {
            setInput(a.target.value);
          }}
          size="large"
          placeholder="Type Here ..."
          className="input-cont"
        />

        <input
          type={'file'}
          ref={inputFile}
          className="input-pick"
          onChange={onChangeFile}
        />
        <a
          onClick={() => {
            inputFile.current.click();
          }}>
          <PaperClipOutlined className="drawer-icon" />
        </a>

        <a
          onClick={() => {
            setEmojyPanel(!emojyPanel);
          }}>
          <SmileOutlined className="drawer-icon" />
        </a>
      </div>
    </Drawer>
  );
};

export default ChatDrawer;
