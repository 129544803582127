import axiosInstance from './axiosInstance';
import { HTTP_METHODS } from 'constants/common';
import { auth } from 'utils/auth';

export default {
  getReport: () => {
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: '/Admin/Report',
      headers: ApiHeader,
    });
  },
  getAdminReport: () => {
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: '/Admin/NewReport',
      headers: ApiHeader,
    });
  },
  getClinicReport: () => {
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: '/Clinic/NewReport',
      headers: ApiHeader,
    });
  },
  getClinicSecoundReport: () => {
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: '/Clinic/Report',
      headers: ApiHeader,
    });
  },
  getDoctorReport: () => {
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: '/Doctor/NewReport',
      headers: ApiHeader,
    });
  },
  getDoctorSecoundReport: () => {
    const { ApiHeader } = auth();
    return axiosInstance({
      method: HTTP_METHODS.GET,
      url: '/Doctor/Report',
      headers: ApiHeader,
    });
  },
};
