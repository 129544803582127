import React from 'react';
import { ROLES } from 'constants/common';
import { Redirect } from 'react-router-dom';

export const auth = () => {
  const { roleId, token } = JSON.parse(localStorage.getItem('user'));
  return {
    Token: 'Bearer ' + token,
    ApiHeader: token ? { Authorization: 'Bearer ' + token } : null,
    Role:
      roleId === 3 ? ROLES.Clinic : roleId === 4 ? ROLES.Admin : ROLES.Doctor,
  };
};
export const getUser = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) return user;
  return <Redirect to="/login" />;
};
