import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Row, Col, Popover } from 'antd';
import moment from 'moment';

import { getUser } from 'utils/auth';
import main from 'apis/main';
import './styles.css';

const Main = () => {
  const [report, setReport] = useState([]);
  const [patients, setPatients] = useState([]);
  const [parents, setParents] = useState([]);
  const [official, setOfficial] = useState([]);

  const defaultImg =
    'https://www.jumpstarttech.com/files/2018/08/Network-Profile.png';

  const { roleId, image } = getUser();
  const ref = useRef(null);

  const getDate = (date) => {
    if (date !== 'no data') {
      var startDate = moment(date, 'M/DD/YYYY HH:mm:ss');
      return `${startDate.format('D')} ${startDate.format('MMMM')}`;
    }
    return <text className="tooltip-text">No Data</text>;
  };

  const LoadReport = async () => {
    if (roleId === 4) {
      const response = await main.getReport();
      setPatients(response.patients);
      setParents(response.parents);
      setOfficial(response.official);
      const adminReport = await main.getAdminReport();
      setReport(adminReport);
    } else if (roleId === 3) {
      const clinicReport = await main.getClinicReport();
      setReport(clinicReport);
      const secondReport = await main.getClinicSecoundReport();
      setPatients(secondReport.patients);
      setParents(secondReport.parents);
      setOfficial(secondReport.official);
    } else {
      const doctorReport = await main.getDoctorReport();
      setReport(doctorReport);
      const secondReport = await main.getDoctorSecoundReport();
      setPatients(secondReport.patients);
      setParents(secondReport.parents);
      setOfficial(secondReport.official);
    }
  };

  useEffect(() => {
    LoadReport();
  }, []);

  return (
    <Row justify="center" align="middle">
      <Col span={24}>
        <Row justify="center" align="middle">
          <Col className="main-col-2">
            <img src={image ? image : defaultImg} className="main-img" />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col>
            <Row className="main-row-2">
              <text className="wlc-text">Welcome</text>
            </Row>
            <Row className="main-row-2">
              <text className="overview-text">
                This is overview of your account
              </text>
            </Row>
          </Col>
        </Row>
        <Row justify="center" align="middle" className="row-padding">
          <Col span={9} />
          <Col span={2}>
            <Row className="center-row">
              <text className="data-text">{patients}</text>
            </Row>
            <Row className="center-row">
              <text>Patients</text>
            </Row>
          </Col>
          <Col span={2}>
            <Col>
              <Row className="center-row">
                <text className="data-text">{official}</text>
              </Row>
              <Row className="center-row">
                <text>Offical</text>
              </Row>
            </Col>
          </Col>
          <Col span={2}>
            <Col>
              <Row className="center-row">
                <text className="data-text">{parents}</text>
              </Row>
              <Row className="center-row">
                <text>Parents</text>
              </Row>
            </Col>
          </Col>
          <Col span={9} />
        </Row>
        <Row justify="center" align="middle" className="main-last-row">
          <Col span={8} className="main-last-col-1">
            <Row>
              {report.length !== 0 &&
                report.map((item) => {
                  const dt = moment(item.date, 'MM/DD/YYYY');
                  const Nesbat =
                    item.newPatients === 0 && item.newParents === 0
                      ? 0
                      : parseInt(
                          (item.newPatients * 100) /
                            (item.newPatients + item.newParents),
                        );
                  return (
                    <Popover
                      content={
                        <Fragment>
                          <Row className="row-width">
                            <Col md={12} lg={12} xs={12}>
                              <text className="popover-header-text">
                                {dt.format('dddd').substr(0, 3)}
                              </text>
                            </Col>
                            <Col md={12} lg={12} xs={12}>
                              <text className="header-date-text">
                                {getDate(item.date)}
                              </text>
                            </Col>
                          </Row>
                          <Row className="row-width">
                            <Col md={16} lg={16} xs={16}>
                              <text className="left-bar">Parents</text>
                            </Col>
                            <Col md={8} lg={8} xs={8}>
                              <text className="right-bar">
                                {item.newParents}
                              </text>
                            </Col>
                          </Row>
                          <Row className="row-width">
                            <Col md={16} lg={16} xs={16}>
                              <text className="left-bar">Patients</text>
                            </Col>
                            <Col md={8} lg={8} xs={8}>
                              <text className="right-bar">
                                {item.newPatients}
                              </text>
                            </Col>
                          </Row>
                          <Row className="row-width">
                            <Col md={16} lg={16} xs={16}>
                              <text className="left-bar">Official</text>
                            </Col>
                            <Col md={8} lg={8} xs={8}>
                              <text className="right-bar">
                                {item.newOfficial}
                              </text>
                            </Col>
                          </Row>
                        </Fragment>
                      }
                      background="white"
                      placement="bottomRight"
                      radius="10"
                      border="#404040"
                      fontSize="10px">
                      <Col span={3} className="col-align" ref={ref}>
                        <Row className="col-align">
                          <Col className="chart-col">
                            <Row
                              style={{
                                borderLeft: ' 6px solid #404040',
                                height: 100 - Nesbat,
                                borderRadius: '3px',
                              }}
                            />
                            <Row
                              style={{
                                borderLeft: ' 6px solid #FE8781',
                                height: Nesbat,
                                borderRadius: '3px',
                              }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <div className="month-text">
                            <text>{dt.format('dddd').substr(0, 3)}</text>
                          </div>
                        </Row>
                      </Col>
                    </Popover>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Main;
