import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Row, Col, Form, Input, Button, Dropdown, Menu, Tooltip } from 'antd';
import { UserOutlined, DownOutlined, MailOutlined } from '@ant-design/icons';

import { Notification } from 'utils/notification';
import categories from 'apis/categories';
import clinic from 'apis/clinic';
import auth from 'apis/authentication';
import './styles.css';

const CreateClinic = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [image, setImage] = useState(null);
  const [phone, setPhone] = useState(null);
  const [doctorName, setDoctorName] = useState(null);
  const [region, setRegion] = useState(null);
  const [states, setStates] = useState([]);

  const inputFile = useRef(null);
  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    auth.upload(file).then((res) => {
      setImage(res);
    });
  };

  const handleMenuClick = (e) => {
    setRegion(states.find((item) => parseInt(item.id) === parseInt(e.key)));
  };
  const getStates = async () => {
    const res = await categories.getCategories();
    setStates(res.states);
  };
  useEffect(() => {
    getStates();
  }, []);

  const menu = (
    <Menu onClick={handleMenuClick}>
      {states.map((item) => {
        return <Menu.Item key={item.id}>{item.name}</Menu.Item>;
      })}
    </Menu>
  );
  return (
    <Row className="row1">
      <Col xs={24} md={4} lg={4} className="row1-col1">
        <input
          type={'file'}
          ref={inputFile}
          className="row1-col1-input"
          onChange={onChangeFile}
        />
        <Tooltip title="Click On Image To Upload Picture" placement="bottom">
          <img
            onClick={() => {
              inputFile.current.click();
            }}
            src={image ? image : require('assets/clinic.jpg')}
            size="large"
            className="row1-col1-img border-typo"
          />
        </Tooltip>
      </Col>
      <Col xs={24} md={14} lg={14} className="row1-col2">
        <Form>
          <Row>
            <Col md={12} lg={12} xs={24}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  size="large"
                  className="border-typo input-typo"
                  placeholder="Name"
                  prefix={
                    <Fragment>
                      <UserOutlined className="input-icon-typo" />{' '}
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  size="large"
                  className="border-typo input-typo"
                  placeholder="Email"
                  prefix={
                    <Fragment>
                      <MailOutlined className="input-icon-typo" />{' '}
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                  size="large"
                  className="border-typo input-typo"
                  placeholder="Password"
                  prefix={
                    <Fragment>
                      <i
                        aria-hidden="true"
                        className="input-icon-typo fa fa-shield"></i>{' '}
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  type="password"
                  size="large"
                  className="border-typo input-typo"
                  placeholder="Password Confirmation"
                  prefix={
                    <Fragment>
                      <i
                        aria-hidden="true"
                        className="input-icon-typo fa fa-shield"></i>{' '}
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xs={24}>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  size="large"
                  className="border-typo input-typo"
                  placeholder="Phone"
                  prefix={
                    <Fragment>
                      <i
                        aria-hidden="true"
                        className="input-icon-typo fa fa-phone"></i>{' '}
                      &nbsp;&nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    setDoctorName(e.target.value);
                  }}
                  size="large"
                  className="border-typo input-typo"
                  placeholder="Doctor Name"
                  prefix={
                    <Fragment>
                      <UserOutlined className="input-icon-typo" /> &nbsp;&nbsp;
                    </Fragment>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Dropdown overlay={menu}>
                  <Button size="large" className="drp-btn border-typo">
                    <text className="drp-text">
                      {region === null ? 'Select Region' : region.name}
                    </text>
                    <DownOutlined className="drp-icon"></DownOutlined>
                  </Button>
                </Dropdown>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    clinic
                      .addClinic({
                        name,
                        stateId: region.id,
                        doctorName,
                        email,
                        password,
                        image,
                        phoneNumber: phone,
                      })
                      .then((res) => {
                        Notification('success', 'clinic added successfully');
                      });
                  }}
                  variant="light"
                  className="btn-typo border-typo">
                  Create
                </Button>{' '}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default CreateClinic;
